
import { useStoreState } from 'easy-peasy';

export default class Features { 

    constructor() {
        this.settings = useStoreState(state => state.siteSettingsModel);
        this.penduser = ['flinksactive']
    }

    isFeatureEnabled = (featured) => {
        let enabled = false;
    
        let features = Object.values(this.settings.websiteSettings)
     
        enabled = features.filter(feature => feature.Name.toLowerCase() === featured.toLowerCase());
      
        return (enabled.length === 0) ? false : true 
    }

    sendPendUser = () => {
        let enabled = false;
        let features = Object.values(this.settings.websiteSettings)
      
        enabled = features.filter(feature => this.penduser.includes(feature.Name.toLowerCase()) && feature.Value === 'true');

        return (enabled.length === 0) ? false : true ;
    }


}