import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "../../../assets/layout/banner.css";
import AxiosWrapper from "../../../services/axios";
import Parser from 'react-html-parser';

function Banner() {
  const mainSettings = useStoreState(
    (state) => state.siteSettingsModel.mainSettings
  );
  const Flow = useStoreState(
    (state) => state.siteSettingsModel.mainSettings.Flow
  );
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const productSettings = useStoreState(
    (state) => state.siteSettingsModel.productSettings
  );
  const useOwnBanner = useStoreState(
    (state) => state.siteSettingsModel.useOwnBanner
  );
  const checkStyle = {
    color: (mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? brandSettings.Secondary : "#fff",
    verticalAlign: "top",
  };

  const axios = new AxiosWrapper();
  const bannerbgImageSurgeReflexDesktop = brandSettings.BannerImage.replace(".png", "") + ((mainSettings.Flow === "STANDALONE" && brandSettings.ProductName === "Reflex") ? ".png" : ".jpg");
  const bannerbgImageSurgeReflexTablet = brandSettings.BannerImage.replace(".png", "") + "-tablet." + ((brandSettings.ProductName === "Surge") ? "jpg" : "png");
  const bannerbgImageSurgeReflexMobile = brandSettings.BannerImage.replace(".png", "") + "-mobile.png";

  const bannerImg = {
    backgroundColor: brandSettings.BannerBgColor,
    backgroundImage: (mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? `url('${bannerbgImageSurgeReflexDesktop}')` : null,
    backgroundSize: "auto 100%",
    backgroundPosition:((brandSettings.ProductName === "Reflex") ? "60% center" : ((mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? "80% center" : "center right")),
    minHeight: ((mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") && productSettings.BannerText.Description.length > 0) ? "500px" : "",
  };

  const bannerImgMobileSurge = {
    backgroundColor: brandSettings.BannerBgColor,
    backgroundImage: `url('${bannerbgImageSurgeReflexMobile}')`,
    backgroundSize: "cover",
    backgroundRepeatY: "no-repeat",
    backgroundPosition: "center",
    padding : "15px 0 15px 10px"
  };

  const bannerImgMobile = {
    backgroundColor: (mainSettings.Flow === "STANDALONE" && brandSettings.ProductName === "Reflex") ? null : brandSettings.BannerBgColor,
    backgroundImage: `url('${bannerbgImageSurgeReflexMobile}')`,
    width: "100%",
    height: "auto",
    backgroundSize: (brandSettings.ProductName === "Surge" || (brandSettings.ProductName === "Reflex" && mainSettings.Flow === "STANDALONE")) ? "100%" : "85%" ,
    backgroundRepeat: "no-repeat",
    backgroundPosition: brandSettings.ProductName === "Surge" ? "center center" : ((brandSettings.ProductName === "Reflex" && mainSettings.Flow === "STANDALONE") ? "" : "right bottom"),
    padding: brandSettings.ProductName === "Surge" ? "16px 0px 16px 10px" : "20px 0px 20px 10px",
    minHeight: (mainSettings.Flow === "STANDALONE" && brandSettings.ProductName === "Reflex") ? "150px" : null,
  };

  const [bannerImgTablet, setBannerImgTablet] = useState({
    fontFamily: "'Century Gothic', sans-serif",
    fontStyle: "normal",
    backgroundColor: (mainSettings.Flow === "STANDALONE" && brandSettings.ProductName === "Reflex") ? null : brandSettings.BannerBgColor,
    backgroundImage: (mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? `url('${bannerbgImageSurgeReflexTablet}')`: null,
    backgroundSize: (brandSettings.ProductName === "Surge") ? (Flow === "PREQUAL" ? "auto 90%" : "auto 100%") : ((mainSettings.Flow === "STANDALONE" && brandSettings.ProductName === "Reflex") ? null : "auto 96%"),
    backgroundPosition: brandSettings.ProductName === "Surge" ? ((getTabletOrientation() === "landscape") ? "50% bottom": (Flow === "PREQUAL" ? "80px bottom" : "right bottom")) : "right bottom",
  });

  useEffect(() => {
  
    setBannerImgTablet({...bannerImgTablet, ...{backgroundPosition: brandSettings.ProductName === "Surge" ? ((getTabletOrientation() === "landscape") ? "right bottom" :((Flow === "PREQUAL") ? "80px bottom" : "right bottom")) : "right bottom",}})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  function getTabletOrientation() {
    // Use a CSS media query to check for landscape orientation
    var landscapeQuery = window.matchMedia("(orientation: landscape)");

    // Return "landscape" if the media query matches
    if (landscapeQuery.matches) {
      return "landscape";
    }
  
    // If landscape query didn't match, assume it's vertical
    return "vertical";
  }

  

  return (
    <div>
      {
        useOwnBanner === true && (<>
      <div
        className={ (brandSettings.ProductName === "Surge" && mainSettings.Flow === "PREQUAL") ? "banner-container banner bannerPosition d-none d-xl-block" : "banner-container banner d-none d-lg-block"}
        style={(!axios.isTablet()) ? bannerImg : bannerImgTablet}
      >
        <div className="container">
          <div className="row">
            <div className={(mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "col-lg-6 banner-content" : "col-lg-8 banner-content"}>
              <div className="row">
                <h1 className={(mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "bannerTextTitle mb-3 mt-3" : "bannerTextTitle"} style={{fontSize : (mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "47px" : "60px"}}>
                  {Parser(productSettings.BannerText.Title)}
                </h1>
                <h4 className="bannerTextSubtitle">
                  {productSettings.BannerText.SubTitle}
                </h4>
              </div>
              <div className="row">
                <table className={ (mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "col-lg-10" : "col-lg-6"}>
                  <tbody>
                    {productSettings.BannerText.Description.length > 0 && productSettings.BannerText.Description.map((i, j) => {
                      return (
                        <tr key={j}>
                          <td className="check align-top" style={checkStyle}>
                            <img src="/checkmark.png" alt="checkmark" />
                          </td>
                          <td>
                            <p className="bannerTextDescription">{i.Text}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className={ (mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "col-lg-12" : "col-lg-6 small"}>
                  <span className="artNotation" style={{fontSize : (mainSettings.Flow !== "PREQUAL" && mainSettings.Flow !== "STANDALONE") ? "13px" : ""}}>
                    {productSettings.BannerText.Note}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center small">
              {(mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? null : (
                <div className="card-image-container p-0 text-center">
                  <img alt="" src={brandSettings.CardImage} className="col-lg-12"/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={ (brandSettings.ProductName === "Surge" && mainSettings.Flow === "PREQUAL") ? "banner-container banner bannerPosition d-none d-sm-block d-xl-none" : "banner-container banner d-none d-sm-block d-lg-none"}
        style={bannerImgTablet}
      >
        <div className="container paddingtablet" style={{maxWidth : "unset"}}>
          <div className="row">
            <div className="col-8 banner-content">
              <div className="row">
                <h1 className="bannerTextTitle">
                  {Parser(productSettings.BannerText.Title)}
                </h1>
                <h4 className="bannerTextSubtitle">
                  {productSettings.BannerText.SubTitle}
                </h4>
              </div>
              <div className="row">
                <table className="col-8">
                  <tbody>
                    {productSettings.BannerText.Description.length > 0 && productSettings.BannerText.Description.map((i, j) => {
                      return (
                        <tr key={j}>
                          <td className="check align-top" style={checkStyle}>
                            <img src="/checkmark.png" alt="checkmark" />
                          </td>
                          <td>
                            <p className="bannerTextDescription">{i.Text}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <span className={ mainSettings.Flow === "PREQUAL"  || mainSettings.Flow === "STANDALONE" ? "col-8 mt-2 artNotation" : "col-12 mt-2 artNotation"}>
                  {productSettings.BannerText.Note}
                </span>
              </div>
            </div>
            <div className="col-4 p-0 align-self-center small">
              {(mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? null : (
                <div className="card-image-container p-0 text-center">
                  <img className="w-100" alt="Brand Card" src={brandSettings.CardImage}/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="banner-container d-block d-sm-none">
        {(mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? (
          <>
            <div className="banner-mobile" style={ brandSettings.ProductName === "Surge" ? bannerImgMobileSurge : bannerImgMobile}>
              <div className="container">
                <div className="row">
                  <div className={(brandSettings.ProductName === "Reflex" && mainSettings.Flow === "STANDALONE") ? "col-6" : "col-7"}>
                    <h1 className="bannerTextTitle mb-2">
                      {productSettings.BannerText.Title}
                    </h1>
                    {mainSettings.Flow === "PREQUAL" &&
                    <h4 className="bannerTextSubtitle">
                      {" "}
                      {productSettings.BannerText.Description.length > 0 && productSettings.BannerText.Description[0].Text.replace("*.","")}
                    </h4>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="banner" style={bannerImgMobile}>
            <h1 className={mainSettings.Flow === "CFC" ? "bannerTextTitle text-center" : "bannerTextTitle"}>
              {Parser(productSettings.BannerText.Title)}
            </h1>
            {(mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE") ? (
              <img alt="" src={mainSettings.Flow === "PREQUAL" ? brandSettings.BannerImage.replace(".png", "") + "-mobile.png" : ''} className="col-lg-12"/>
            ) : (
              <div className="card-image-container text-center">
                <img alt="" src={brandSettings.CardImage} className="col-lg-12"/>
              </div>
            )}
          </div>
        )}
        {mainSettings.Flow === "PREQUAL" || mainSettings.Flow === "STANDALONE" ? (
          <div className="banner-content reverse">
            {mainSettings.Flow === "PREQUAL" && 
            <h3>
              <b>See if you pre-qualify</b>
            </h3>}
            <table className="col-lg-8">
              <tbody>
                {productSettings.BannerText.Description.length > 0 && productSettings.BannerText.Description.map((i, j) => {
                  return (
                    <tr key={j}>
                      <td className="check align-top" style={checkStyle}>
                        <FontAwesomeIcon icon={faCircle} />
                      </td>
                      <td>
                        <label>{i.Text}</label>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div>
              <small>{productSettings.BannerText.Note}</small>
            </div>
          </div>
        ) : (
          <div className="banner-content reverse cfc1">
            <div>
              <small>{productSettings.BannerText.Note}</small>
            </div>
            <div className="text-center pb-3 ">
              <h3>Apply Now</h3>
            </div>
          </div>
        )}
      </div></>)}
    </div>
  );
}

export default Banner;
