
let text = { 
    landing: {
        block1: {
            title:              "Accept Your {ProductName} Mastercard® Offer Now",
            reservationNumber : "Enter your Reservation Number",
            securityNumber :    "And your Social Security Number"
        },
        block2: {
            title : "Need Help?"
        }
    },
    terms : {
        block1: {
            title : "Accept Your {ProductName} Mastercard® Offer Now",
            income : "Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis repaying this obligation.",
            disclaimerAdditionalCardholder : "(Additional Fee of $30.00) Cannot be the same name as the primary cardholder.",
            tooltipIncome : "Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.",
            tooltipAge : "You must be at least 18 years of age to apply",
            tooltipPhone : "When you give us your mobile phone number, we have your permission to contact you at that number about all your Continental Finance accounts. <br><br> - Your consent allows us to use text messaging, artifical or prerecorded voice messages and account service calls, but not for telemarketing or sales calls. <br><br> - It may include contact from companies working on our behalf to service your accounts. <br> message and data rates may apply. <br><br> You may contact us any time to change these preferences."
        },
        block2: {
            title : "Choose Your Card!",
        },
    },
};

text = {
    ...text,
    reservation: {
        ...text.landing,
        block1: {
            ...text.landing.block1,
            title: "Reservation Number Look Up",
            reservationDescription: "We can check if we have a reservation match for you",
            lastName: "Last Name",
            zip: "Zip Code",
            last4ssn: "Last 4 of SSN",
            last4ssnNotFoundNotPrequal: "The information you have entered does not match our records, please check the information and re-enter.",
            last4ssnNotFoundPrequal: "The information you have entered does not match our records, please check the information and re-enter. Click below to see if you pre-qualify.",
            last4ssnExpiredNotPrequal: "The reservation number has expired.",
            last4ssnExpiredPrequal: "The reservation number has expired. You may have a good chance to pre-qualify, please click below to try",
        },
        block2: {
            ...text.landing.block2,
            redirectionLine1: "We were able to find your reservation. Now let's continue...",
            redirectionLine2: "Redirecting now.",
        }
    }
}

export default text;