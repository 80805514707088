import React from 'react';
import { useStoreState } from 'easy-peasy';

import AppSiteFooter from './appsitefooter';
import CLIFooter from './clifooter'

import '../../assets/layout/footer.css';

function Footer() {

    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);

    const footerStyle = {
        backgroundColor: brandSettings.FooterBgColor || "",
        color: mainSettings.Flow === "CLI" ? "#666": "#fff"
    };
    

    return (
        <div className="footer-container" style={footerStyle}>
            {mainSettings.Flow === "CLI" ? <CLIFooter/> :
                <AppSiteFooter />
            }
        </div>
    );
}

export default Footer;