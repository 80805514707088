import axios from './axios';


export default class marketing  {

    //To search = httpGet, httpGetBodyParams, httpPost, httpPostBodyParams
    
    constructor() {
       
        this.axios = new axios();

        this.keys = { "Google" : ["gclid","_gl", "wbraid", "gbraid"], "Facebook" : ["fbclid"], "Tiktok" : ["ttclid"], "Pinterest" : ["epik"], "Mntn" : ["mntn_id"], "CrossSell" : ["cfccsui"], "Microsoft" : ["msclid"]}
        this.parameters = {
            "ProductId"    : sessionStorage.getItem("ProductId"),
            "WebSite"      : window.location.href,
            "Provider"     : sessionStorage.getItem("defaultTrafficSource"), 
            "UtmSource"    : "-", 
            "UtmMedium"    : "-",
            "UtmCampaign"  : "-", 
            "UtmContent"   : "-", 
            "UtmTerms"     : "-",
            "Channel"      : "-", 
            "SubChannel"   : "-", 
            "ClickId"      : "-",
            "SessionId"    : sessionStorage.getItem("oldSessionToken"),
            "Device"       : (this.axios.isTablet()) ? "Tablet" : ((this.axios.isMobile()) ? "Mobile" : "Desktop"),
            "UserAgent"    : navigator.userAgent.toLowerCase(),
            "Referrer"     : Document.referrer || "-",
            "UIVersion"    : process.env.REACT_APP_VERSION || "local"
        }

        this.map = {
            "UtmSource"   : ["utm_source"],
            "UtmMedium"   : ["utm_medium"],
            "UtmCampaign" : ["utm_campaign"],
            "UtmContent"  : ["utm_content"],
            "UtmTerms"    : ["utm_terms", "utm_term"],
            "Channel"     : ["channel"],
            "SubChannel"  : ["sub_chanel"],
            "ClickId"     : ["cfccsui", "msclid", "epik", "fbclid", "ttclid", "mntn_id", "hid", "gclid", "_gl", "gbraid", "wbraid"]
        }

      
    }
   

    /**
     * HTTP GET verb with the option to pass data through URL parameters
     * @param {String} endpoint Target API endpoint, format: '/endpoint?param=param'
     */
    mapper = async () => {
        let parameters = this.getParameters();
        this.mapValues(parameters);
        this.parameters.Provider = this.getProvider(parameters);
        
        this.axios.post("save-tracking",this.parameters).then((id) => {
           
            sessionStorage.setItem("trackingId", typeof id === 'object' ? id.Payload : id);
            sessionStorage.setItem("trafficInfo", JSON.stringify(this.parameters));
        });

        

    }

    getProvider = (array = {}) => {

        let provider = this.parameters.Provider;

        for(const value of array){
            let index = value[0];
            
            for(const key of Object.keys(this.keys)){
                let Pkey = key;
                let Provider = this.keys[key];
                if(Provider.includes(index.toLowerCase())){
                    if(provider === "Organic"){
                        return Pkey;
                    }
                }
            }
        }

        if(provider === "Organic" && this.parameters.UtmSource !== "-"){
            return this.parameters.UtmSource[0].toUpperCase() + this.parameters.UtmSource.substring(1).toLowerCase();
        }

        return provider;
    }

    mapValues = (parameters) => {

        let sample = parameters;
        let topIndex = 9999;
        for(const value of parameters){
            let index = value[0];
            let content = value[1];
            
            for(const key of Object.keys(this.map)){
               
                let field = key;
                let options = this.map[key];
                for(const pkey of options){
                   
                    if(pkey === index){
                        if(field === "ClickId"){
                            if(options.indexOf(pkey) < topIndex){
                                this.parameters[field] = (content === "$_id") ? sessionStorage.getItem("sessionToken") : content;
                                topIndex = options.indexOf(pkey)
                            }
                        }
                        if(this.parameters[field] === "-"){
                            this.parameters[field] = content;
                        }
                    }
                }
               
            }
        }

        return sample;
    }

    getParameters = () => {
  
        // Address of the current window
        let address = window.location.search
      
        // Returns a URLSearchParams object instance
        let parameterList = new URLSearchParams(address)
      
        // Created a map which holds key value pairs
        let map = new Map()
      
        // Storing every key value pair in the map
        parameterList.forEach((value, key) => {
            map.set(key, value)
        })
      
        // Returning the map of GET parameters
        return map
    }

    updateAppId = (id) => {

        let parameters = this.getParameters();
       
        this.parameters.Provider = this.getProvider(parameters);
        
        this.mapValues(parameters);
        
        let bulk = this.parameters;
        bulk["applicantId"] = id;
        bulk["id"] = sessionStorage.getItem("trackingId") || "";
        
        this.axios.post("save-tracking",bulk);

        return this;
    } 

    trackEvent = (step, flow, applicantId, productId, category, event) => {
        let bulk  = {
            StepId : step,
            flow : flow,
            applicantId : applicantId,
            productId : productId,
            category : category,
            sessionId : sessionStorage.getItem("oldSessionToken"),
            SiteEvent : event,
            webSite : "https://"+window.location.hostname
        }

        this.axios.post("insert-waterfall-tracking-info", bulk);
    }

    postBackQueue = async (data) => {
        let bulk  = {
            idList : data.idList,
            applicantId : data.applicantId,
            productId : data.productId,
            siteEvent : data.siteEvent,
            hid : this.axios.findParam("hid") || "",
            sessionId : sessionStorage.getItem("oldSessionToken"),
            productCode : data.productCode,
            pubId : (this.axios.findParam("pub") || this.axios.findParam("pubid")) || "",
            site : "https://"+window.location.hostname
        }

        return await this.axios.post("save-post-back-queue", bulk);
    }

    updateSessionReport = async (eventName, value = false) => {

        let bulk = {
            ReportId : sessionStorage.getItem("ReportId"),
        }

        bulk[eventName] = (value === false) ? true : value;

        await this.axios.post("update-webapps-report", bulk);

        return this;
    }

    mntnLocation = async () => {
        
        var b = "4.0.0",
            n = "33430",
            additional = "term=value",
            t, r, i;
        try {
            t = window.top.document.referer !== "" ? encodeURIComponent(window.top.document.referrer.substring(0, 2048)) : ""
        } catch (o) {
            t = document.referrer !== null ? document.referrer.toString().substring(0, 2048) : ""
        }
        try {
            r = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location
        } catch (u) {
            r = document.location
        }
        try {
            i = window.top.parent.location.href !== "" ? encodeURIComponent(window.top.parent.location.href.toString().substring(0, 2048)) : ""
        } catch (a) {
            try {
                i = r !== null ? encodeURIComponent(r.toString().substring(0, 2048)) : ""
            } catch (f) {
                i = ""
            }
        }
        // eslint-disable-next-line  no-unused-vars
        var l, c = document.createElement("script"),
            h = null,
            p = document.getElementsByTagName("script"),
            d = Number(p.length) - 1,
            v = document.getElementsByTagName("script")[d];
        if (typeof l === "undefined") {
            l = Math.floor(Math.random() * 1e17)
        }
        // eslint-disable-next-line  no-useless-concat
        h = "dx.mountain.com/spx?" + "dxver=" + b + "&shaid=" + n + "&tdr=" + t + "&plh=" + i + "&cb=" + l + additional;
        c.type = "text/javascript";
        c.src = ("https:" === document.location.protocol ? "https://" : "http://") + h;
        v.parentNode.insertBefore(c, v)    
    }

    mtnnConversion = async (refNumber) => {
       
       
        var p;
        var q;
        var m;
        var o = "33430";
        var l = refNumber; // l="ORDER ID",
        var i = "TOTAL ORDER AMOUNT";
        var c = "";
        var k = "";
        var g = "";
        var j = "";
        var u = "";
        var shadditional = "";
        try {
            p = window.top.document.referer !== "" ? encodeURIComponent(
                window.top.document.referrer.substring(0, 512)
            ) : ""
        } catch (n) {
            p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : ""
        }
        try {
            q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location
        } catch (b) {
            q = document.location
        }
        try {
            m = window.parent.location.href !== "" ? encodeURIComponent(window.parent.location.href.toString().substring(0, 512)) : ""
        } catch (z) {
            try {
                m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : ""
            } catch (h) {
                m = ""
            }
        }
        var A, y = document.createElement("script"),
            w = null,
            v = document.getElementsByTagName("script"),
            t = Number(v.length) - 1,
            r = document.getElementsByTagName("script")[t];
        if (typeof A === "undefined") {
            A = Math.floor(Math.random() * 100000000000000000)
        }
        w = "dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional;
        y.type = "text/javascript";
        y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w;
        r.parentNode.insertBefore(y, r)
       
    }

    amazonConversion = (Pixel) => {
        var _pix = document.getElementById(Pixel.Tag.split("|")[0]);
        if (!_pix) { 
            var protocol = '//'; 
            var a = Math.random() * 1000000000000000000; 
            _pix = document.createElement('iframe'); 
            _pix.style.display = 'none'; 
            _pix.setAttribute('src', protocol + Pixel.Tag.split("|")[1] + '&cb=' + a); 
            _pix.setAttribute('id',Pixel.Tag.split("|")[0]); 
            document.body.appendChild(_pix);
        }
    }

    InsertPixel = (Pixel, Tag) => {
        var _pix = document.getElementById(Tag);
        if (!_pix) { 
          
            _pix = document.createElement('iframe'); 
            _pix.style.display = 'none'; 
            _pix.setAttribute('src', Pixel); 
            _pix.setAttribute('id',Tag); 
            document.body.appendChild(_pix);
        }
    }

    pushnami = (Product) => {

        let apiKeys = {"Surge" : "5e3d8cff29555b102035f00e", "Reflex" : "5e3d8d1929555b102035f013"}

       
        var my_awesome_script = document.createElement('script');

        my_awesome_script.setAttribute('src','https://api.pushnami.com/scripts/v1/push/' + apiKeys[Product] || "");

        document.head.appendChild(my_awesome_script);

    }

    metadata = (ProductName = "", BannerUrl = "") => {
     
        document.head.innerHTML+=`
        <meta name='description' content='Check to see if you have a pre-approved `+ProductName+` Mastercard offer.'>
        <meta name='keywords' content='`+ProductName+` Mastercard, Continental Finance, Double Credit Limit, Credit Cards for Bad Credit, credit card application'>
        <meta name='author' content='Continental Finance Company, LLC'>

        <meta property='og:url'                content='https://`+window.location.hostname+`/' />
        <meta property='og:type'               content='website' />
        <meta property='og:title'              content='Double Your Credit Limit with `+ProductName+` Mastercard' />
        <meta property='og:description'        content='Check to see if you have a pre-approved `+ProductName+` Mastercard offer.' />
        <meta property='og:image'              content='`+BannerUrl+`' />
        <link rel="canonical" href="https://`+window.location.hostname+`/" />
        `
    }
}
