import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Parser from 'react-html-parser';
import '../../../../assets/terms/applicantdisclosure.css';

const TC = (props) => {
    const ProductCode = localStorage.getItem("TCProductCode");
    const [Template, setTemplate] = useState("");
    
    useEffect(() => {
        document.title = "Terms and Conditions";
        let client = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            timeout: 60000,
            headers: { 
                "Access-Control-Allow-Origin": "*", 
                "Content-Type": "application/json", 
                "authorization": 'bearer '+localStorage.getItem("sessionToken") 
            }})

        client.get('get-pdf-html-template/'+ProductCode).then((response) => {

            if (response.status === 200) {
                if (response.data.StatusCode === 1) {
                    setTemplate(response.data.Payload || response.data);
                   
                   
                    return ;
                }
            }
            //window.print();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    function print(){
        //setPrinting(false)
        window.print()
        //setPrinting(true)
    }

    return (
        <>
           
            <div className='row d-print-none'>
                <div className='col-12 pt-1 pb-1 text-center'>
                    <button onClick={() => {print()}} className='btn btn-primary pt-2 pb-2'>Download</button>
                </div>
            </div>
            <div className='row toPrint'>
                <div className='col-12'>
                    <h3 style={{"fontWeight": "bold", "textAlign": "center", "margin": "2% 0"}}>ACCOUNT OPENING DISCLOSURES</h3>
                    <div id="applicant-disclosure"  className='nowidth nocscroll'>
                        {Parser(Template)}
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default TC;