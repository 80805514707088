import React from 'react';

function GrandBankFooter() {

    return (
        <div className='footer-container' style={{backgroundColor : "#375e7b"}}>
            <div className='container p-0' >
                <div className='col-12 p-0 mb-0'  >
                    <div className='col-12'>
                        <p className='text-justify mb-1 mt-1' style={{color: "white", fontWeight : "500", fontSize: "12pt"}}>
                            The Grand.bank Mastercard debit card is issued by Grand Bank for Savings, FSB. Member FDIC. Under license from Mastercard International.
                        </p>
                        <br></br>
                        <p className='text-justify mb-1 mt-1' style={{color: "white", fontWeight : "500", fontSize: "12pt"}}>
                        Important Information About Procedures for Opening a New Account or Establishing a New Customer Relationship: The USA PATRIOT Act is a federal law that helps the government
                        fight the funding of terrorism and money laundering activities. Federal law requires all U.S. financial institutions to obtain, verify and record information that identifies each person who opens
                        an account or establishes a customer relationship with Grand Bank for Savings, FSB. What this means for you: When you open a bank account, we will ask you for your name, address, date
                        of birth, and other information that will allow us to reasonably identify you. We may also ask to see your driver's license or other identifying documents at any time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default GrandBankFooter;