
export default {
    defaultText: {
        flags : [
            // {"flag" : "FROZEN", "text" : "We were unable to obtain a copy of your credit report as an identifier is required for access.  In order to continue processing your application you must send us your Experian identifier to the address listed above. We must receive this information within 30 days from the date of this letter. If we do not receive it by that date, we will regrettably be unable to give further consideration to your credit request."}, 
            {"flag" : "SYS_ERROR" , "text" : "We are currently processing your application and you will receive a decision within 30 days."},
            {"flag" : "", "text" : "We are unable to verify your identity."},
            {"flag" : "INVALIDSTATE", "text" : "We do not currently do business in the state that you reside in"},
            {"flag" : "DECLINEDINFO", "text" : "After a thorough review of your request for credit, we are unable to approve your application at this time. We will notify you within 30 days at the address you provided with the reasons for our decision."},
            {"flag" : "DECLINEDCBR", "text" : "We have found some errors with your credit bureau report. Please contact the bureau to correct your report, then you may come back to reapply with us after 30 days."},
            {"flag" : "DECLINEDPENDINFO", "text" : "We are unable to verify your identity."},
            {"flag" : "CVILOW", "text" : "Unfortunately, we are not able to complete the processing of your application right now. Please check your email for important information from us regarding additional information we need."}
        ],
        ribbon : [
            {"flag" : "SYS_ERROR", "text" : "Your application is being processed"},
            {"flag" : "DECLINEDINFO", "text" : "Thank you for applying..."},
            {"flag" : "DECLINEDCBR", "text" : "Thank you for applying..."},
            {"flag" : "DECLINEDPENDINFO", "text" : "Thank you for applying..."},
            {"flag" : "CVILOW", "text" : "Your Pre-qualification Results Are..."}
        ]
    }
};
