import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import Banner from "../../../body/banners/banner";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import { useEffect } from "react";
import marketing from "../../../../services/marketing";
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import UserDataFormVertical from "../../global-elements/UserFormDataVertical";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import { FormProvider, useForm } from "react-hook-form";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import Features from "../../global-elements/feature";
import Security from "../../global-elements/security";
import Reputation from "../../global-elements/reputation";
import Trustbox from "../../global-elements/trustbox";
import '../../../../assets/prequal/prequal.css';
import ValidatorService from "../../../../services/validator";
import SubmitApplicationService from "../../../../services/submitApplication";
import AxiosWrapper from "../../../../services/axios";
import AdobeScript from "../../../../services/adobeScript";
import FeaturesService from "../../../../services/features";

function Landing() {
    const ada = new AudioEyeSupport();
    const Setting = useStoreState((state) => state.siteSettingsModel);
    const utms = new marketing();
    const info = useStoreState(state => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const useRecaptcha = useStoreState(state => state.siteSettingsModel.useRecaptcha);
    const pendingInfo = ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL", "CVILOW"];
    const history = useHistory();
    const Submit = SubmitApplicationService()
    const [errorShow, setErrorShow] = useState(false);
    const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const methods = useForm({mode: 'onSubmit'});
    const Pixels = new PixelsService();
    const setInitPixels = useStoreActions(actions => actions.setInitPixels);
    const [errorMessage, setErrorMessage] = useState(false);
    const closeError = () => setErrorShow(false)
    const initPixels = useStoreState(state => state.initPixels);
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const InvalidState = useStoreState((state) => state.invalidState);
    const setInvalidState = useStoreActions((actions) => actions.setInvalidState);
    const validatorService = new ValidatorService();
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const setGrandBanckDeclined = useStoreActions(actions => actions.siteSettingsModel.setGrandBanckDeclined);
    const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    const idList = useStoreState(state => state.siteSettingsModel.idList);
    const setIdList = useStoreActions(actions => actions.siteSettingsModel.setIdList);
    const domain = useStoreState(state => state.siteSettingsModel.domain);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const flinkClient = new AxiosWrapper(process.env.REACT_APP_DE_BASE_URL);
    const adobeAds = new AdobeScript()
    const [isHalfScrolled, setIsHalfScrolled] = useState(false);
    const setUseOwnBanner = useStoreActions(actions => actions.siteSettingsModel.setUseOwnBanner);
    const features = new FeaturesService();

    useEffect(() => {
        console.log(features.sendPendUser())
        if(!initPixels){
            utms.mapper();
            ada.setup();
            ada.LoadNeuroId().setNid(Setting.brandSettings.ProductName).setup().setDYHeaders(process.env.REACT_APP_PREQUAL).setDYContext({ type: "HOMEPAGE" }).DYLandingEvent().setDYBanner(setUseOwnBanner);
            adobeAds.setup()
           
            utms.trackEvent(1, "Prequal", "", Setting.productSettings.ProductId, "Flow");
            if (Setting.productSettings.TrafficSource.toLowerCase().match(new RegExp(/icomm/g))) {
                utms.postBackQueue({ idList, applicantId: "", productId: Setting.productSettings.ProductId, siteEvent: 'Landed', productCode: Setting.mainSettings.ProductCode
                }).then((payload) => {
                    (idList === "") ? setIdList(payload) : setIdList(idList + "," + payload);
                })
            }
            setTimeout(() =>  adobeAds.startEvent('page_view'), 5000);
            utms.pushnami(Setting.brandSettings.ProductName);
            Pixels.initialize().pageView("/landing").event("google", "tracking", "landing");
            getRoutingSettings({ Url: domain, Route: window.location.pathname});
            setInfo({ index : 'ProductCode', value : Setting.mainSettings.ProductCode })
            setInitPixels(true)
            setAlResult(false)
        }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(flinkClient.findParam("fhash")){
            flinkClient.post("api/flinks/RetrieveFlinksStatus", { receiptId : flinkClient.findParam("fhash") }).then((result) => {
                if(result){
                    setApplicationResult(result);
                    setGrandBanckDeclined(result.IsGrandbank || false)
                    setdisplayTerms(true);
                    if(result.result.toUpperCase() === "APPROVED"){ history.push("/terms" + window.location.search); return; }
                    setdisplayResult(true)
                    history.push("/declined" + window.location.search)
                    return;
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const fullHeight = document.body.scrollHeight;
        const scrolledToBottom = scrollPosition + windowHeight;

        if (scrolledToBottom >= fullHeight / 2) {
        console.log()
        if(isHalfScrolled === false){
            
            setIsHalfScrolled(true)
        }
        
        }
    };
    
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHalfScrolled]);

    useEffect(() => {
    if (isHalfScrolled) {
        scrollEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHalfScrolled]);

    const scrollEvent = () => {
    adobeAds.event('scroll')
    };

    const validateSubmition = async (data) => {
        
        setLoading(true)

            if(flinkClient.findParam('useValidatorV2') && process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION"){
                let result = await validatorService.addressValidateV2(info, methods, setInvalidState);
                if(result === "error"){
                    setErrorShow(true);
                    setErrorMessage('Address Service is down or returns some errors (this window will not go to PROD)')
                    setLoading(false)
                    return;
                }
            }

            //PATCH remeber to remove this
            if(data.State === 'WA'){
                setInvalidState(true); 
                setApplicationResult({result : "INVALIDSTATE"}) 
                return;
            }
            ///////////////////////////////////

            if (!validatorService.preValidations(data, methods, info) ) {
                //if(await validatorService.addressValidateV2(info, methods, setInvalidState)){
                    if(await validatorService.EmailValidate(info.Email, methods)){
                        let result = await validatorService.addressValidate(info, methods, setInvalidState);
                        if(result === "INVALIDSTATE") { 
                            setInvalidState(true); 
                            setApplicationResult({result : "INVALIDSTATE"}) 
                            return;
                        }

                        if(result !== false){
                            await submit();
                        }
                    }
                    
                //}
                
            }
        setLoading(false)
    }

    const submit = async () => {
        try{
           
    
            let result = await Submit.submitApplication(info);

            if(result){
                //GrandBank Stuff
                setGrandBanckDeclined(result.IsGrandbank || false);
                setApplicationResult(result);
                //--------------//

                //Invalid State -> Improved please
                if(result.result.toLowerCase() === "statenotvalidforbusiness"){ setInvalidState(true); return false; }
                //--------------//

                //Marketing stuff need improved
                window.nid('applicationSubmit');
                ada.DYSubmitionEvent(result.ref_number)
                utms.trackEvent(2, "Prequal", result.ref_number, Setting.productSettings.ProductId, "Flow");
                if (Setting.productSettings.TrafficSource.toLowerCase().match(new RegExp(/icomm/g))) {
                    utms.postBackQueue({ idList, applicantId: result.ref_number, productId: Setting.productSettings.ProductId, siteEvent: 'Submitted', productCode: Setting.mainSettings.ProductCode
                    }).then((payload) => {
                        (idList === "") ? setIdList(payload) : setIdList(idList + "," + payload);
                    })
                }
                //--------------//
                window.scrollTo(0, 0);
                //Approved Call (need an strategy or something here)
                if(result.result.toUpperCase() === 'APPROVED'){
                    setdisplayTerms(true);
                    history.push("/terms"+window.location.search)
                    return;
                }
                //--------------//
                setdisplayResult(true)
                //this will go away in the future
                if(result.failed_metric !== null && ["PENDINGFLINKS", "DECLINEFLINKS"].includes(result.failed_metric.toUpperCase() || "")){  history.push("/link-account"+window.location.search); return; }
                //--------------//

                //New Flinks call for Dynamic call
                if(features.isFeatureEnabled('FlinksActive')  && result.result.toUpperCase() === 'PENDUSER') {
                    history.push("/link-account"+window.location.search); return;
                }
                //--------------//
                //pendinfo call (we need to joi nthis with the approved call, maybe some matrix?)
                if(pendingInfo.includes(result.result.toUpperCase())){ history.push("/pendinfo"+window.location.search); return; }
                //--------------//

                //Default exit
                history.push("/declined"+window.location.search)
                return true;
                //--------------//
            }
            setErrorShow(true);
            setErrorMessage('An unknown error has occured. Please try again.')
            return false;
        }catch(Error){
            console.log(Error);
            setErrorShow(true);
            setErrorMessage('An unknown error has occured. Please try again.')
        }
    } 

    return (
    <>
        <Banner />
        <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError}/>
        <InvalidStateModal visible={InvalidState} />
        <div className="" style={{backgroundColor: "#eee"}}>
            <div className="container pt-5 pb-5" >
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(validateSubmition)} className="user-form shaded">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="input-fields">
                                        <UserDataFormVertical disabledFields={isLoading} />
                                        <div className="captcha-container">
                                            {useRecaptcha && Setting.productSettings.CaptchaKey !== null && (
                                                <div className="form-group displayFlexCenter">
                                                    <Recaptcha setRecaptchaDisabled={setRecaptchaDisabled} ProductName={brandSettings.ProductName}/>
                                                </div>
                                            )}
                                        </div>
                                        <button 
                                        onClick={() => {Pixels.event("google", "PREQUAL", "Submit Application"); adobeAds.event('see_my_card_offers')}}
                                        disabled={isLoading || ((useRecaptcha && Setting.productSettings.CaptchaKey !== null) &&recaptchaDisabled)} id="submit-app" style={{backgroundColor : Setting.brandSettings.Tertiary}} className="btn text-small mt-3 btn-block" type="submit">
                                        {isLoading ? 'Submitting...' : 'See My Card Offers'}
                                        </button>
                                        
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <Security />
                                        </div>
                                        <div className="col-12">
                                            <Reputation />
                                        </div>
                                        <div className="col-12">
                                            <Trustbox />
                                        </div>
                                        <div className="col-12">
                                            <Features />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    </>
  );
}

export default Landing;
