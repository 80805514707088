/**
 * Masking. 
 *
 * We pass an input string value and a placeholder format using the X letter
 * to mark the places where we are going to set each input char, then we return the result
 * 
 * @param {String}  input  Base input value = 1234567890.
 * @param {String}  mask   Mask placeholder that we are going to use = (XXX) XXX-XXXX.
 *
 * @return {String} We return a masked value = (123) 456-7890
 */
    export function masking(input = "", mask){
        //We declare the BASE Character we use on our mapping
        const flag = "X";
        try {
            //If we don't have text to evaluate we finish the process
            if(input === ""){ return ""; }
            //We fetch all the input in an array to inject later in all the marked spaces on the mask
            let array = input.split('');
            //Iterate over the mask and replace the first ocurrence of our FLAG with an element of the input
            for (let i = 0; i < array.length; i++) {
                mask = mask.replace(flag, array[i]);          
            }
            //We remove all the ocurrences of our replace FLAG and return the result
            return (mask.replace(mask.substring(mask.indexOf("X")), "") !== "") ? mask.replace(mask.substring(mask.indexOf("X")), "").replaceAll(flag,"") : mask.replaceAll(flag, "");
    
        } catch (error) {
            //Console any error captured
            console.log(error)
        }
    }

/**
 * UnAsking. 
 *
 * We receive the original event, the elements that we want to remove of the mask and the original value from the state
 * we replace the elements marked as empty and use also use the original unformated value to check if what the user is
 * deleting is an element of the template to remove an extra value from the result and not get stuck in a loop
 * 
 * @param {Object}  e  Event object of the input this receive the value masked.
 * @param {String}  mask   Mask elements that must be remove from the template = "()- ".
 * @param {String}  original   Original info to prevent a stuck loop deleting info.
 *
 * @return {String} We return a unmasked value = 1234567890
 */
    export function unmasking(e, mask, original) {
        //We declare the BASE Character we use on our mapping
        const flag = "X";
        //We set the initial value removing our pointer flag
        let base = e.target.value.replaceAll(flag, "");
        try {
            //Get the elements to remove in an array
            //We fetch all the mask in an array to remove later from the input
            let array = mask.replaceAll(flag, "").split(''); 
            //We remove those elements from the base string
            for (let i = 0; i < array.length; i++) {
                base = base.replaceAll(array[i], '');
            }
            // Then we cut the result to match the mask length
            base = base.substring(0, mask.length - array.length);
            //Is the input action is backward we remove an element from the string
            base = (e.nativeEvent.inputType === "deleteContentBackward" && array.includes(base.charAt(base.length - 1))) ? base.substring(base.length - 1) : base;
            //Before we return the result we check if we don't hit on the backback a masked element so we check the string against the original
            //and remove an extra is the values are the same
            return (e.nativeEvent.inputType === "deleteContentBackward" && original === base) ? base.slice(0, -1) : base;
        } catch (error) {
            //Console any error captured
            console.log(error)
        }
    };

    export function IncomeMask(annualIncome){
        
      annualIncome = ""+annualIncome;
      if(annualIncome.includes(".")){
      
        annualIncome = annualIncome.substring(0, annualIncome.indexOf('.') + 3);
      }
      if(annualIncome.slice(-1) === "."){
         
        return "$"+ commafy(parseInt(annualIncome)) 
      }
       
        if (!annualIncome || annualIncome === "") {
          return "$";
        } else {
          return (
            
           
           "$"+ commafy(parseInt(annualIncome))
            
          );
        }
    }

    function commafy( num ) {
      var str = num.toString().split('.');
      if (str[0].length >= 3) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join('.');
  }
    