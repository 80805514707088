import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import './maintenance.css'
import MaintenanceMan from './Maintenance-art.png'



const Maintenance = () => {
  return (
    <Container >
        <Row className='row-maint'>
            <Col ></Col>
            <Col xs={8}>
                <div class="panel-body text-center">
                  <div class="padding-top-4">
                    <img alt="" src={MaintenanceMan}></img>
                  </div>
                  <h1 className="text-primary">Under Maintenance</h1>
                  <h3>We are currently working on making improvements.</h3>
                  <h3>Please check back later.</h3>
                </div>
            </Col>
            <Col></Col>
        </Row>
    </Container>
  )
}

export default Maintenance