import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useStoreState} from 'easy-peasy';

const ErrorModal = ({size, show, message, closeError}) => {
  const setting = useStoreState((state) => state.siteSettingsModel);

  return (
    <Modal
        size={size}
        show={show}
        onHide={closeError}
        backdrop="static"
        centered
      >
        <Modal.Body>
            <h4>Error</h4>
            <p>
                {message}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button 
            size='sm' 
              onClick={closeError} 
              style={{ 
                backgroundColor: setting.mainSettings.Flow.toUpperCase() === "ACTIVATION_PAYMENT" ? setting.brandSettings.BannerBgColor : "",
                borderColor: setting.mainSettings.Flow.toUpperCase() === "ACTIVATION_PAYMENT" ? setting.brandSettings.BannerBgColor : ""
              }}
            >
              Close
            </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ErrorModal