import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import '../../../assets/cookieConsent.css'

function CookieConsent() {
    const Setting = useStoreState(state => state.siteSettingsModel);
    const setCookieConsent = useStoreActions(action => action.siteSettingsModel.setCookieConsent)
  
    const accept = async () => {
        localStorage.setItem("userActiveConsent", true);
        if(typeof window.DY !== 'undefined' && typeof window.DY.API === 'function'){
            window.DY.userActiveConsent = { accepted: true }
           
        }
        setCookieConsent(true)
       
    }

    const decline = () => {
        localStorage.setItem("userActiveConsent", false);
        if(typeof window.DY !== 'undefined' && typeof window.DY.API === 'function'){
            window.DY.userActiveConsent = { accepted: false }
           
        }
        setCookieConsent(false)
    }

    return (
        <div className="cookie-consent-banner">
            <div className="cookie-consent-banner__inner">
                <div className="cookie-consent-banner__copy">
                    <div className="cookie-consent-banner__header">THIS WEBSITE USES COOKIES</div>
                    <div className="cookie-consent-banner__description">We use cookies to understand how you use our site and to improve your experience. By continuing to use our website or by closing this notice, you acknowledge that you agree to our use of cookies and other tracking technologies in accordance with our <a rel="noopener noreferrer" target='_blank' href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/privacy-notice/CFC-Cookie-Policy.pdf"}>Cookie Policy</a>.</div>
                </div>

                <div className="cookie-consent-banner__actions ">
                    <button onClick={() => accept()}  className="cookie-consent-banner__cta marginMobileCookieConsent" style={{backgroundColor: '#FF5F00', color: "#fff" }}>
                        Accept
                    </button>
                    
                    <button onClick={() => decline()} className="cookie-consent-banner__cta cookie-consent-banner__cta--secondary marginMobileCookieConsent noleftonmobile">
                        Decline
                    </button>
                </div>
            </div>
        </div>

        
    );
}

export default CookieConsent;