import { action } from 'easy-peasy';
export default {
    applicant: {
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Suffix: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Email: "",
        CreditProtectionPrice:"1.39",
        ConfirmEmail: "",
        PrimaryPhone: "",
        PrimaryPhoneSms: "",
        SecondaryPhone: "",
        SecondaryPhoneSms: "",
        dob: "",
        SSN: "",
        MonthlyIncome: "",
        IncomeSource: "", 
        HasCheckingAccount: "", 
        cashIntent: "",
        ReservationCode: "",
        Resident: false,

        // Terms
        eSignConsent: false,
        creditProtection: "",
        tcAcknowledgement: "false",
        emailOptIn: false,
        cardColorCode: "",
        generalAcknowledgement: "",
        SMSConsent: false,
        authorize : false,
        authPrequal : false,
        eConsentAcknowledgement : false,

        // Additional Cardholder
        hasAdditionalCardholder: "",
        additionalCardholderFirstName: "",
        additionalCardholderMI: "",
        additionalCardholderLastName: "",
        additionalCardholderSuffix: "",

        // Spouse Information
        isMarried: "",
        spouseFirstName: "",
        spouseLastName: "",
        spouseMiddleInitial: "",
        spouseSuffix: "",
        spouseHasDifferentAddress: "",
        spouseAddress1: "",
        spouseAddress2: "",
        spouseCity: "",
        spouseState: "",
        spouseZip: "",
        spouseEmail: "",

        // Tracking
        sessionId: "",
        srcId: "",
        sid: "0",
        pubId: "",
        subId: "",
        hid: "0",
        c1: "",
        c2: "",
        c3: "",
        gclid: "",
        gAdsExpirationDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
        fbclid: "",
        fbAdsExpirationDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
        msclid: "",
        msAdsExpirationDate:new Date(Date.now() + 24 * 60 * 60 * 1000),

        // Site Settings
        webSite: "",
        engineID: "",
        isPrequal: "true",
        pid: "",
        ProductCode: "",
        productId: "",
        appRef: "",
        step: 1,
        termsId: "",
    },

    setApplicant: action((state, payload) => {
    
        state.applicant[payload.index] = payload.value;
    }),

    storeApplicantData: action((state, payload) => {
        state.applicant = { ...state["applicant"], ...payload };
    }),

    setCompleteApplicant: action((state, payload) => {
        state.applicant = payload;
    }),
}