import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { Link, useHistory } from "react-router-dom";

function Enjoy() {
  const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
  const applicant = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
  const payment = sessionStorage.getItem("Payment");
  const history = useHistory();

  useEffect(() => {
    return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
  }, [history]);

  return (
    <div className="container pendfee-container">
      <div className="row">
        <div className="col-lg-12 congratulations">
          {payment === "1" ? <h2>Thank you for your payment!</h2> : <h2>Payment pending...</h2>}
          {payment === "1" ?
          <div className="instructions-text">
            <div><strong>Your payment of $95.00 for the processing fee has been accepted.</strong></div>
            <div><strong>If you've already paid, your {brandSettings.ProductName.toUpperCase()} Mastercard will arrive in 7-10 days at which time you can activate it.</strong></div>
            <div><strong>If you paid by checking or savings account</strong>, the mailing of your {brandSettings.ProductName.toUpperCase()} card account will be delayed up to 10 days to allow for payment to clear.</div>
          </div>
          :
          <div className="instructions-text">
          <div><strong>If you're paying by MoneyGram, Western Union or by phone please include your reference number.</strong></div>
          <div>You'll still receive your card in the mail in 7-10 days at which time you can activate it.</div>
          <div>If you'd like to make your payment now so you can activate your card as soon as it arrives, 
            <Link to="/payment" > click here.</Link>
          </div>
        </div>
          }
        </div>
      </div>
      <div className="row card-box">
        <div className="col-lg-6">
          <img src={brandSettings.CardImage} alt="" className="col-lg-12" />
        </div>
        <div className="col-lg-6 result-message">
          <div>
            <span className="credit-limit-number">
              ${applicationResult.credit_limit || "400"}
            </span>
            <span className="credit-limit-text">Credit Limit</span>
          </div>
          <div>
            <div className="attribute">Processing Fee: </div>
            {/* this needs to change when we include processinf fee in the backend response */}
            <div>$95.00</div>
          </div>
          <div>
            <div className="attribute"> Approved Applicant: </div>
            <div>
              {applicant.FirstName || "Test"} {applicant.LastName || "Test"}
            </div>
          </div>
          <div>
            <div className="attribute">Your Reference Number: </div>
            <div>{applicationResult.ref_number || "16212152"}</div>
          </div>
        </div>
      </div>
      <div className="instructions-text">
        {/* <div >
          Be sure to <Link to="/payment">activate</Link> so you can use your new card immediately.
        </div> */}
        <div className="font-weight-bold">Thank you and enjoy your new {brandSettings.ProductName.toUpperCase()} Mastercard!</div>
      </div>
    </div>
  );
}

export default Enjoy;