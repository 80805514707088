import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import marketing from "../../../../services/marketing";
import PixelsService from '../../../../services/pixels';
import CreditProtection from "../../add-ons/CreditProtection";
import ColorCardChoiceForm from "../../global-elements/colorCardChoiceForm";
import Acknowledgements from "../../add-ons/Acknowledgements";
import EConsent from "../../global-elements/eConsent";
import TC from "../../add-ons/TC";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import ConfirmSmsConsent from "../../global-elements/confirmsSmsConsent";
import ValidatorService from "../../../../services/validator";
import SubmitApplicationService from "../../../../services/submitApplication";
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import AdobeScript from "../../../../services/adobeScript";

function Terms() {
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const [isLoading, setLoading] = useState(false);
  const utms = new marketing();
  const history = useHistory();
  const applicantResult = useStoreState((state) => state.applicationResultModel.applicationResult);
  const info = useStoreState(state => state.applicantModel.applicant);
  const methods = useForm({mode: 'onSubmit'});
  const [errorShow, setErrorShow] = useState(false);
  const termsPixels = useStoreState(state => state.termsPixels);
  const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
  const [errorMessage, setErrorMessage] = useState(false);
  const closeError = () => setErrorShow(false)
  const Pixel = new PixelsService();
  const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
  const validatorService = new ValidatorService();
  const Submit = SubmitApplicationService()
  const resultStep = useStoreActions(actions => actions.setDisplayResult);
  const ada = new AudioEyeSupport();
  const adobe = new AdobeScript();

  useEffect(() => {      
    if (!termsPixels) {
      Pixel.pageView("/terms").event("google","tracking","Terms").event("tiktok", "-", "ClickButton");
        utms.trackEvent(3, "Prequal", applicationResult.ref_number, Setting.productSettings.ProductId, "Flow");
        if(typeof window.nid === 'function') {
          window.nid('closeSession')
        
        }
        adobe.event('prequal_offer')
        ada.setDYContext( { type: "OTHER" , data:["Offer", applicationResult.ref_number ]}).sendDYEvent({name : 'prequal_offer'});
        getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
        setTermsPixel(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const validateSubmition = async (data) => {

    setLoading(true)
      if (!validatorService.preValidations(data, methods, info)) {
        await submit(data);
      }
    setLoading(false)
  }

  const submit = async (data) => {
    try{
      utms.trackEvent(5, "Prequal", applicationResult.ref_number, Setting.productSettings.ProductId, "Flow");
      Pixel.event("google", "tracking", "ClickAcceptMyOfferButton").event("Pinterest", "custom","ClickAcceptMyOfferButton");

      let result = await Submit.submitApplicationOffer(info);
      setApplicationResult(result);
      window.scrollTo(0, 0);
      if(result){
        resultStep(true)
        if(result.decision === "APPROVED"){
          ada.sendDYEvent({name : 'prequal_accept'});
          setApplicationResult({ result: "APPROVED" });
          history.push(`/approved`+window.location.search);
          return true;
        }
        history.push(`/declined`+window.location.search);
        return true;
      }
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
    }catch(Error){
      console.log(Error);
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
    }
    return false;

  }

  return (
    <>
      {/* Error Modal */}
      <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError}/>
      {/* Error Modal */}
      
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(validateSubmition)} className="user-form ">
            {/* Top Ribbon */}
            <div className="ribbon" style={{ backgroundColor: Setting.brandSettings.Primary }}>
              <h1 className='mb-0'>You are Pre-Approved! To Get Your Card ...</h1>
            </div>
            {/* Step to step Proccess */}
            <div className='container step-process'>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h5 className="terms-header-title" style={{fontSize : "21pt"}}>Congratulations! You are just 3 Steps Away From Getting Your New Card</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <img alt="" src={Setting.webAssetsBaseUrlSettings.CdnImage + "Common/step01.png"} />
                    </div>
                    <div className="col-lg-4">
                        <img alt="" src={Setting.webAssetsBaseUrlSettings.CdnImage + "Common/step02.png"} />
                    </div>
                    <div className="col-lg-4">
                        <img alt="" src={Setting.webAssetsBaseUrlSettings.CdnImage + "Common/step03.png"} />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-lg-12 mb-4 text-center">
                        <button type="button" onClick={() => document.getElementById("accept-card-focus").focus()} className="btn" style={{ backgroundColor: Setting.brandSettings.Secondary }}>ACCEPT YOUR CARD</button>
                    </div>
                </div>
            </div>
            {/* Explanation Stuff */}
              <div className="container whitebg ">
                <div className="row">
                    <div className="col-lg-12">
                        <p>
                            You have been Pre-Approved for a {Setting.brandSettings.ProductName} Mastercard® with an initial credit limit of ${applicantResult.credit_limit}.
                        </p>
                        <p><b>To complete your application, you must review the information below and press submit.</b></p>
                        <p>
                            -&nbsp;&nbsp;&nbsp;Applicant Disclosures <br />
                            {Setting.mainSettings.Flow.toUpperCase() === "PREQUAL" && Setting.productSettings.ProductId === 1 ? <>-&nbsp;&nbsp;&nbsp;Choose your card design, either standard or premium <br /></>: <></>}
                            -&nbsp;&nbsp;&nbsp;Optional Continental Credit Protection
                        </p>
                        <p>
                            Then agree to the Acknowledgements and click the “Click here
                            to sign and submit your application” below to complete your
                            application.
                        </p>
                    </div>
                </div>
                {/* Applicant Disclousures */}
                <div className="row">
                  <div className="col-12">
                      <h1 className="terms-header-title">Pricing and Terms</h1>
                  </div>
                </div>
                <div className="row terms-wrapper">
                  
                  <div className="col-12">
                    <TC data={{ productCode: applicantResult.product_code }}></TC>
                  </div>
                </div>
                <div id='accept-card-focus'  tabindex="1">
                  
                </div>
                  
                <div  className="row">
                  <ColorCardChoiceForm></ColorCardChoiceForm>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                      <h1 className="terms-header-title">Credit Protection</h1>
                  </div>
                  <CreditProtection />
                </div>
                <div className="row mtop-30">
                    <div className="col-lg-12">
                        <div className="input-container">
                            <h1 className="terms-header-title">Acknowledgements</h1>
                            <h5 style={{padding : "0px 10px"}}>YOU MUST INDICATE YOU'VE READ AND CONSENT TO THE BELOW STATEMENTS:</h5>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                  <EConsent />
                  
                </div>
                <div className="row mb-2">
                  <Acknowledgements />
                  
                </div>
                {
                    Setting.smsConcentModulePrequal === true  && (
                        <ConfirmSmsConsent />
                    )
                }
                <div className="row mtop-30 mb-4 text-center">
                    <div className="col-lg-12">
                        <button
                            disabled={isLoading}
                            id="btn-submit-application"
                            className="btn"
                            style={{ backgroundColor: Setting.brandSettings.Secondary, width: "90%" }}
                            onClick={() => { utms.updateSessionReport("acceptOfferButton"); adobe.event('prequal_accept', Setting.CardColors.find(value => value.Code === info.cardColorCode))  }}
                        >Click here to sign and submit your application »</button>
                    </div>
                </div>
              </div>
          </form>
      </FormProvider>
       
    </>
  );
}

export default Terms;
