import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useFormContext } from "react-hook-form";
import { masking, unmasking } from "../../../services/masking";

function ConfirmSmsConsent() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const [cursor, setCursorPosition] = useState(1)
    const { register,  formState: { errors }, clearErrors } = useFormContext();

    const getBankType = () => {
        return (Setting.brandSettings.BankName === "Celtic Bank") ? "celtic" : "tbom";
    }

    useEffect(() => {
        if(info.PrimaryPhoneSms === info.PrimaryPhone){
            clearErrors("PrimaryPhoneSms")
        };

        if(info.SecondaryPhoneSms === info.SecondaryPhone){
            clearErrors("SecondaryPhoneSms")
        };

    },[info.PrimaryPhoneSms, info.PrimaryPhone, info.SecondaryPhoneSms, info.SecondaryPhone, clearErrors])

    return (
        <>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="input-container" >
                                <label htmlFor="PrimaryPhoneSms">Confirm your Primary Phone Number</label>
                                    <input 
                                        value={masking(info.PrimaryPhone, "(XXX) XXX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo( { index : 'PrimaryPhone', value : unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhone)});
                                                setInfo( { index : 'PrimaryPhoneSms', value : unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhoneSms)})
                                            }
                                        }} 
                                        onKeyDown={(e) => {
                                            setCursorPosition(e.target.selectionStart)
                                        }}
                                        onKeyUp={(e) => {
                                           
                                            if(e.nativeEvent.key === "Backspace" ){
                                                e.target.setSelectionRange(cursor -1, cursor -1);
                                            }

                                        }}
                                        type="text" className="form-control" placeholder="(999) 999-9999" {...register("PrimaryPhoneSms")}    
                                    />
                                   
                                </div>
                                { errors.PrimaryPhoneSms && ( <span className='error-message'> {errors.PrimaryPhoneSms.message} </span> ) }
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="input-container" >
                                <label htmlFor="SecondaryPhoneSms">Confirm your Secondary Phone Number</label>
                                <input 
                                        value={masking(info.SecondaryPhone, "(XXX) XXX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo( { index : 'SecondaryPhone', value : unmasking(e, "(XXX) XXX-XXXX", info.SecondaryPhone)});
                                                setInfo( { index : 'SecondaryPhoneSms', value : unmasking(e, "(XXX) XXX-XXXX", info.SecondaryPhoneSms)})
                                            }
                                        }} 
                                        onKeyDown={(e) => {
                                            setCursorPosition(e.target.selectionStart)
                                        }}
                                        onKeyUp={(e) => {
                                           
                                            if(e.nativeEvent.key === "Backspace" ){
                                                e.target.setSelectionRange(cursor -1, cursor -1);
                                            }

                                        }}
                                        type="text" className="form-control" placeholder="(999) 999-9999" {...register("SecondaryPhoneSms")} 
                                    />
                                   
                                </div>
                                { errors.SecondaryPhoneSms ? errors.SecondaryPhoneSms && <span className='error-message'> This field is missing or invalid. Please fill it correctly. </span> : '' }
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-check p-0 check-fields">
                        <div className="input-container">
                            <p className="form-check-label pt-0">
                            I agree to receive transactional text messages such as alerts about my {Setting.brandSettings.ProductName} Mastercard account. Message frequency will vary, and message and 
                            data rates may apply. Providing your consent is not required to obtain an account from the issuing bank. To opt-out at any time, you can reply “STOP” to any messages received.
                            For assistance, reply “HELP” to any message received, or call 1-866-449-4515 Monday through Friday 7:00 am to 12:00 midnight EST, Saturday and Sunday 8:00 am to 8:00 pm EST. 
                            See the <a href={Setting.webAssetsBaseUrlSettings.Cdn + "pdf/terms/short_message_service_terms_of_use_" + getBankType() + ".pdf"} target="_blank"  rel="noopener noreferrer">Short Message Service (SMS) Terms of Use</a> and <a href={Setting.productSettings.OmpsUrl} target="_blank"  rel="noopener noreferrer">Online Mobile Privacy Policy</a> for more information. This opt-out does not apply to Fiserv 2Way Connect&#8480; related messages that I may be receiving.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <table>
                        <tbody>
                            <tr>
                              
                                <td>
                                    <div className="form-check">
                                        <input checked={info.SMSConsent} onClick={(e) => setInfo({ index: "SMSConsent", value: info.SMSConsent === true ? false : true, })} className="form-check-input" type="checkbox" value="" id="smsConsent" {...register("smsConsent")} />
                                        <label className="form-check-label" htmlFor="smsConsent">
                                            I agree to the SMS Terms of Use
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ConfirmSmsConsent;