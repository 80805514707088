import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './services/registerServiceWorker';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Metadata from './models/Metadata';
import { StoreProvider } from 'easy-peasy';
import store from './config/Store';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const metaFlag = window.location.host.replace(/[0-9]/g, '').replace("www.","").replace(":","").replace("test", "").replace("qa","");
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
        <StoreProvider store={store}>
          <HelmetProvider>
            <Helmet prioritizeSeoTags>
              <title>{(Metadata[metaFlag]) ? (Metadata[metaFlag].title || "") : ""}</title>
              <meta name="description" content={(Metadata[metaFlag]) ? (Metadata[metaFlag].description || "") : ""} />
              <meta name="keywords"    content={(Metadata[metaFlag]) ? (Metadata[metaFlag].keywords || "") : ""} />
              <meta name="author"      content={(Metadata[metaFlag]) ? (Metadata[metaFlag].author || "") : ""} />
              <meta property='og:url'  content={"https://"+window.location.hostname} />
              <meta property='og:type'  content={(Metadata[metaFlag]) ? (Metadata[metaFlag]["og:type"] || "") : ""} />
              <meta property='og:title'  content={(Metadata[metaFlag]) ? (Metadata[metaFlag]["og:title"] || "") : ""} />
              <meta property='og:description'  content={(Metadata[metaFlag]) ? (Metadata[metaFlag]["og:description"] || "") : ""} />
              <meta property='og:image'  content={(Metadata[metaFlag]) ? (Metadata[metaFlag]["og:image"] || "") : ""} />
              <link rel="canonical" href={"https://www."+window.location.hostname} />
              
            </Helmet>
            <App />
          </HelmetProvider>
        </StoreProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

