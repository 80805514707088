import React from 'react';
import { useStoreState} from 'easy-peasy';

function Reputation() {

    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);

    const bbbLink = "https://www.bbb.org/delaware/business-reviews/credit-cards-and-plans/continental-finance-company-llc-in-newark-de-21003066/#sealclick";
    const bbbImage = "https://seal-delaware.bbb.org/seals/blue-seal-187-130-bbb-21003066.png";

    return (
        <div id="add-on-reputation" className="add-ons-section">
            <div className="col-lg-12">
                <h2><b>A Reputation People Trust</b></h2>
            </div>
            <br/>
            <div className="col-lg-12">
                <a href={bbbLink} target="_blank" rel="nofollow noopener noreferrer">
                    <img src={bbbImage} style={{ border: "0", height: "150px" }} alt="Continental Finance Company, LLC BBB Business Review" />
                </a>
            </div>
            {mainSettings.Flow.toUpperCase() === "PREQUAL" ?
                <div className="col-lg-12">
                    <h4>
                        <b>Highly rated financial services company</b>
                    </h4>
                </div>
                : null}
            {mainSettings.Flow.toUpperCase() === "PREQUAL" ?
                <div className="col-lg-12">
                    <p>
                        Continental Finance, one of America's leading marketers and servicers of credit cards for consumers with less-than perfect credit,
                        is proudly rated A+ by the Better Business Bureau.
                </p>
                </div>
                : null}
        </div>
    );
}

export default Reputation;