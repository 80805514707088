import React, { useEffect, useState } from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy'

import marketing from '../../../services/marketing';
import { useFormContext } from 'react-hook-form';

import '../../../assets/terms/acknowledgements.css'
import AudioEyeSupport from '../../../services/audioEyeSupport';
import AdobeScript from '../../../services/adobeScript';

function Acknowledgements({data}) {
    const applicantInfo = useStoreState((state) => state.applicantModel.applicant);
    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);  
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const [ProductCode, setProductCode] = useState("")
    const { register,  formState: { errors } } = useFormContext();    
    const utm = new marketing();
    const activeCheck = useStoreState((state) => state.siteSettingsModel.activateChecks); 
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const ada = new AudioEyeSupport()
    const adobe = new AdobeScript();

    useEffect(() => {
        setProductCode((applicationResult.product_code !== "") ? applicationResult.product_code : ((applicantInfo.ProductCode !== "") ? applicantInfo.ProductCode: mainSettings.ProductCode))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            <div  className='col-lg-12 terms-acknowledgements'>
                <div className='terms-acknowledgements-box'>
                    <p>
                        I acknowledge that by checking this box and pressing the "Submit" button below,
                        I am completing and signing this Application electronically. I understand that I am applying to {brandSettings.BankName} for a 
                        {brandSettings.ProductName.toLowerCase() === 'fit' ? `${" "+brandSettings.ProductName.toUpperCase()}` : `${" "+brandSettings.ProductName}`} Mastercard® credit card and that by submitting this application I am authorizing the following: Obtain and use credit bureau
                        reports on me, check my employment history, contact me by any means, including mail, phone, text, or e-mail, and exchange information
                        about me in connection with this Application and any update, extension of credit, review, or collection of my Account. Finally,
                        I have read all of the <a   href='/tc' target="_blank" className="button-link" onClick={() => {utm.updateSessionReport("termsLink"); localStorage.setItem("TCProductCode", data.productCode)}} >Rates, Fees, Costs and Limitations</a>, <a  onClick={() => { utm.updateSessionReport("privacyLink") }}  href={productSettings.PrivacyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, 
                        and <a onClick={() => { utm.updateSessionReport("eConsentLink") }} href={ (brandSettings.ProductName === "Revel") ? "https://revelcardinfo.com/omps.html" : productSettings.OmpsUrl} target="_blank" rel="noopener noreferrer">Online Mobile Privacy Statement</a>, agree with all of the terms, and have printed or retained an electronic copy for my records.
                    </p>
                    {
                        activeCheck && (
                            <table>
                                <tbody>
                                    <tr>                        
                                        <td>
                                            <div className="form-check">
                                                <input  checked={info.generalAcknowledgement === "1" ? true : false} value={info.generalAcknowledgement} onClick={(e) => {  setInfo({ index: "generalAcknowledgement", value: info.generalAcknowledgement === "1" ? "0" : "1" }); utm.updateSessionReport("iAgreeButton") }} className="form-check-input" type="checkbox" id="generalAcknowledgement" {...register("generalAcknowledgement")} />
                                                <label className="form-check-label" htmlFor="generalAcknowledgement">
                                                    I agree to the Acknowledgements
                                                </label>
                                            </div>
                                            {errors.generalAcknowledgement ? 
                                                <div className="error-message"> You must acknowledge before continue. </div>
                                                :
                                                <></>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    }
                    
                </div>
                {
                    
                    mainSettings.Flow === "CFC" && brandSettings.ProductName === "Fit" && (
                    <div className="col-lg-12 p-0 mt-3">
                        <div className='terms-acknowledgements-box'>
                        <p>
                        I consent and understand that {brandSettings.BankName} and Continental Finance Company will use any information I provide to apply for a credit product to enable 
                        physical and digital communications from selected affiliated and non-affiliated marketing partners in compliance with the <a rel="noopener noreferrer" href={productSettings.PrivacyUrl} target="_blank">Privacy Policy</a>. My consent is not required 
                        for approval of this application. If I receive any email communications from affiliated or non-affiliated marketing partners, I understand that I can unsubscribe at 
                        any time by clicking the unsubscribe link in that email message.
                        </p>
                        {
                            activeCheck && (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input onClick={() => { }} className="form-check-input" type="checkbox" value="" id="emailOffers" {...register("emailOffers")} />
                                                    <label className="form-check-label" htmlFor="emailOffers">
                                                        I consent and understand
                                                </label>
                                                </div>
                                                {errors.emailOffers ? 
                                                    <div className="error-message"> You must acknowledge before continue. </div>
                                                    :
                                                    <></>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        }
                        
                    </div>
                </div>)
                }
            </div>
            {
                !activeCheck && (
                    <div className='col-12 mt-3 terms-acknowledgements'>
                        <div className="form-check">
                            <input 
                                checked={info.generalAcknowledgement === "1" ? true : false} value={info.generalAcknowledgement}
                                onClick={() => { 
                                    let value =  info.generalAcknowledgement === "1" ? "0" : "1";
                                    setInfo({ index: "generalAcknowledgement", value})
                                    setInfo({ index: "eSignConsent", value })
                                    setInfo({ index: "tcAcknowledgement", value })
                                    ada.sendDYEvent({ name : 'AcknowledgementsClickEvent'});
                                    adobe.event('acknowledgment_checkpoint')
                                }} 
                                className="form-check-input" 
                                type="checkbox" id="generalAcknowledgement" {...register("generalAcknowledgement")} 
                            />
                            <label className="form-check-label" >
                                I have read and agree to the 
                                <a rel="noopener noreferrer" target="_blank" href={productSettings.ESignUrl}> Electronic Communications Disclosure</a>, 
                                <a rel="noopener noreferrer" href='/tc' target="_blank" onClick={(e) => {utm.updateSessionReport("disclosureLink"); localStorage.setItem("TCProductCode", ProductCode)}}> Terms and Conditions</a> and Acknowledgements. 
                            </label>
                        </div>
                        {errors.generalAcknowledgement ? 
                            <div className="error-message"> You must acknowledge before continue. </div>
                            :
                            <></>
                        }
                    </div>
                )
            }
           
        </>
    )    
}

export default Acknowledgements