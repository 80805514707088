import React from 'react';
import { useStoreState } from "easy-peasy";

class AudioEyeSupport extends React.Component {

    constructor() {
        super();
        this.Setting = useStoreState((state) => state.siteSettingsModel);
    }

    setup = () => {
        if(this.Setting.mainSettings.AudioEye !== null){
            
            let hash = this.Setting.mainSettings.AudioEye
            console.log("Loading Audio Eye Support: " + hash)
            var b = function() {
                window.__AudioEyeSiteHash = hash;
                var a = document.createElement("script");
                a.src = "https://wsmcdn.audioeye.com/aem.js";
                a.type = "text/javascript";
                a.setAttribute("async", "");
                document.getElementsByTagName("body")[0].appendChild(a)
                console.log("Audio Eye Support inserted!")
            };
            "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", b) : window.attachEvent && window.attachEvent("onload", b) : b();
        }
        return this
    }

    setDYContext = (contex) => {
        
            window.DY = window.DY || {};
            window.DY.recommendationContext = contex;
            window.DY.noCookies = true;
            console.log(localStorage.getItem('userActiveConsent'))
            if(localStorage.getItem('userActiveConsent') !== null){
                window.DY.userActiveConsent = { accepted: localStorage.getItem('userActiveConsent') }
            }
            
            return this
    }

    setDYHeaders = (key) => {
        document.head.innerHTML+=`
        <link rel="preconnect" href="https://cdn.dynamicyield.com"> 
        <link rel="preconnect" href="https://st.dynamicyield.com"> 
        <link rel="preconnect" href="https://rcom.dynamicyield.com"> 
        <link rel="dns-prefetch" href="https://cdn.dynamicyield.com"> 
        <link rel="dns-prefetch" href="https://st.dynamicyield.com"> 
        <link rel="dns-prefetch" href="https://rcom.dynamicyield.com">`

        var api_dynamic = document.createElement('script');

        api_dynamic.setAttribute('src','https://cdn.dynamicyield.com/api/' + key + '/api_dynamic.js');
        api_dynamic.setAttribute('type','text/javascript');

        document.head.appendChild(api_dynamic);

        var api_static = document.createElement('script');

        api_static.setAttribute('src','https://cdn.dynamicyield.com/api/' + key +'/api_static.js');
        api_static.setAttribute('type','text/javascript');

        document.head.appendChild(api_static);

        
       
        return this;
    }
    sendDYEvent = (event) => {
        if(typeof window.DY !== 'undefined' && typeof window.DY.API === 'function'){
            window.DY.API("event", event)
            this.checkAndSetCookie();
        }
        return this
    }

    DYLandingEvent = () => {
        setTimeout(() => this.sendDYEvent(
            {
                name: "Application", 
                properties: { 
                    dyType : "add-to-cart-v1", 
                    productId : "Application",
                    value : 1,
                    quantity : 1,
                    applicationId : sessionStorage.getItem("oldSessionToken")
                }
            }), 5000)

            return this
    }

    DYSubmitionEvent = (refNumber = '') => {
        this.sendDYEvent(
        {
            name: "Submission",   
            properties: {
                uniqueTransactionId: refNumber, 
                dyType : "purchase-v1", 
                value : 1,
                applicationId : sessionStorage.getItem("oldSessionToken"),
                cart : [
                    {
                        productId : "Application",
                        quantity: 1,
                        itemPrice : 1.00
                    }
                ]

            }
        });
        return this;
    }

    setDYBanner = (setBanner) => {
        var interval;
        setTimeout(() => {
            interval = setInterval(() => {
                if(typeof window.DYO !== 'undefined' && typeof window.DYO.getRenderedObjectsOnPage === 'function'){
                    let result = window.DYO.getRenderedObjectsOnPage();
                    console.log(result)
                    console.log(Object.keys(result).length === 0)
                    if(Object.keys(result).length === 0){
                        console.log("setting own banner")
                        setBanner(true)
                    }
                    
                    clearInterval(interval)
                }
            }, 500)
        },1500)
         
        
        setTimeout(() => {
            if(typeof window.DYO === 'undefined' && typeof window.DYO.getRenderedObjectsOnPage !== 'function'){
                console.log("banner failsafe")
                setBanner(true)
                clearInterval(interval);
            }
        }, 4000); 
    }

    LoadNeuroId = () => {
        (function(n,e,u,r,o,i,d){
            n.nid=n.nid||function(){(n.nid.q=n.nid.q||[]).push(arguments)};
            o=e.createElement(u);i=e.getElementsByTagName(u)[0];o.async=1;
            o.src=r;i.parentNode.insertBefore(o,i);
            })(window,document,'script','//scripts.neuro-id.com/c/nid-knelt487'+((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? '-test' : '')+'.js')
           return this
    }

    setNid = (ProductName) => {
        window.nid('identify', sessionStorage.getItem("oldSessionToken"));
        window.nid('setVariable', 'funnel', ProductName)

        return this
       
    }

    checkAndSetCookie = () => {
        var dyidCookie = localStorage.getItem('_dyid');
        var dyidServerCookie = localStorage.getItem('_dyid_server');
        if (dyidCookie !== null && dyidServerCookie === null) {
            console.log("setting DY server value")
            // Cookie _dyid exists, setting a new one with the same value and expiration time
            localStorage.setItem('_dyid_server', dyidCookie)
        }
    }
    
    getCookie = (name) => {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1, cookie.length);
            }
        }
        return false;
    }
}

export default AudioEyeSupport