import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useFormContext } from "react-hook-form";

import '../../../assets/terms/e-disclosure.css';

function Esign() {

    const { register, formState: { errors } } = useFormContext();
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const Setting = useStoreState((state) => state.siteSettingsModel);
    const activeCheck = useStoreState((state) => state.siteSettingsModel.activateChecks);

    return (
        <div id="e-sign" className="col-12">
            <div id="content-1">
                <p><b>Electronic Communications Disclosure and Consent&nbsp;</b></p>
                <p>This Electronic Communications Disclosure and Consent (this &ldquo;Disclosure and Consent&rdquo;) applies to all communications for products, services, and accounts made available by or accessible through the online service, mobile application (the &ldquo;Mobile App&rdquo;), or website (collectively, the &ldquo;Services&rdquo;).</p>
                <p>The words &quot;we,&quot; &quot;us,&quot; and &quot;our&quot; refer to (i) the entity with which you have your account and its affiliates, including but not limited to the creditor for your account and issuer of your credit card (Celtic Bank or The Bank of Missouri), and (ii) Continental Finance Company, LLC, which provides services for your account.</p>
                <p>By accepting this Disclosure and Consent:</p>
                <ul>
                    <li>You acknowledge that you have had the opportunity to read this Disclosure and Consent.</li>
                    <li>You agree to the terms of this Disclosure and Consent.</li>
                    <li>You agree to receive electronic versions of the documents associated with your account(s).</li>
                    <li>You agree to use electronic signatures in place of written signatures.</li>
                    <li>You agree to maintain an active and valid email address.</li>
                </ul>
                <p><b>Electronic Communications Disclosure&nbsp;</b></p>
                <p>You agree that we may provide you with any communications in electronic format, and that we may discontinue sending certain paper communications to you, unless and until you withdraw your consent as described below.&nbsp;&nbsp;These items include, at minimum, the following, as well as any notices of modifications to these items:</p>
                <ul type="disc">
                    <li>All legal and regulatory disclosures and communications associated with your account or the product or service you obtain from or through us. As an example, we may send by email legally required notification of changes to terms and conditions related to your account(s) or the Services.</li>
                    <li>Notices or disclosures about a change in the terms of your account or associated payment feature and responses to claims.</li>
                    <li>Privacy policies and notices</li>
                    <li>If you separately indicate to us your preference to enroll in the Paperless Statements program, monthly (or other periodic) billing or account statements for your account(s) or such other communications we may include from time to time as part of the Paperless Statements program (&quot;Paperless Statements&quot;).</li>
                </ul>
                <p>Your consent to receive electronic communications does not automatically enroll you in Paperless Statements. You must separately indicate to us your preference for paperless enrollment. &nbsp;</p>

                <p>We reserve the right, in our sole discretion, to discontinue the provision of your electronic communications, or to terminate or change the terms and conditions on which we provide electronic communications. We will provide you with notice of any such termination or change as required by law.</p>

                <p><b>Method of Delivery</b></p>
                <p>All communications that we provide to you in electronic form will be provided by one or more of the following methods: (1) via e-mail to the email address you provided, (2) your online account (whether accessed via website or the Mobile App), or (3) other electronic notification methods (i.e., push notifications, text messages). &nbsp;This may require you download and view a .pdf file containing the communication.</p>

                <p>You also certify any email address you provide is a valid email address for the purposes described in this Disclosure and Consent. You agree to maintain an email address until you provide us with a new one by contacting customer service or through your online account. If you allow someone else to access your email address, you accept the risk that they may see your sensitive information.</p>

                <p><b>Withdrawal of Consent</b></p>
                <p>You may withdraw your consent to this Disclosure at any time by changing your delivery preferences within the online services, the Mobile App, or by contacting customer service. If you withdraw your consent, you will receive paper versions of the legally required documents associated with your account.&nbsp;</p>

                <p>At our option, we may treat the provision of an invalid e-mail address, or the subsequent malfunction of a previously valid e-mail address, as a withdrawal of consent to receive communications electronically. We will not impose any fee to process the withdrawal of your consent to receive electronic communications. Any withdrawal of your consent to receive electronic communications will be effective only after we have a reasonable period of time to process your withdrawal.</p>

                <p><b>Access to Paper Copies</b></p>
                <p>You can request paper copies of electronic communications by contacting customer service and, when available, you can access a printable version of these communications through your online account. We retain copies of these communications for the time periods required by law and will provide you with copies of these upon request within those time periods. We do not necessarily retain copies for longer than is required by law. Save or print copies of communications to ensure you have them when needed. Your request for a paper copy of a particular communication is not a withdrawal of consent to receive communications electronically.</p>

                <p>We reserve the right to provide communications in paper form at all times at our discretion even if you have given us consent to provide them electronically. Communication, such as statements, when sent in paper form, may include sensitive account information, such as name, address, and other account-related information.</p>

                <p><b>System Requirements</b></p>
                <p>Your access device (defined as the electronic device you use to access your account and view electronic documents) must meet the following requirements:</p>

                <ol>
                    <li>The most current version of Chrome, Safari, Firefox or Edge to access your Account through the online service or if accessing your Account through our mobile application, one of the following mobile operating systems: Apple iOS 15.0 or later or Android OS 12.0 or later</li>
                    <li>Local, electronic storage capacity to view and retain electronic communications and/or a printer to print them</li>
                    <li>An active email accounts and software to access it</li>
                    <li>The current version of an operating system capable of connecting to the internet to support access to electronic communications</li>
                    <li>The current version of software to permit display of .pdf files (such as&nbsp;Adobe&reg; Acrobat&reg;&nbsp;Reader)</li>
                </ol>

                <p>The aforementioned system requirements apply to all access devices you may use to access your account and view electronic documents. If you change access devices (or use multiple access devices), it is your responsibility to ensure that the other access devices meet these system requirements and that you are still able to access and view your electronic documents. &nbsp;By accepting this Disclosure and Consent, and consenting to receive electronic documents,&nbsp;you&nbsp;confirm that each access device you will use to access electronic documents satisfies these system requirements.</p>

                <p><b>Changes to System Requirements</b></p>
                <p>We will notify you if the terms of this Disclosure and Consent change in such a way that may prevent you from accessing or viewing your electronic documents.&nbsp;</p>

                <p><b>Federal Law</b></p>
                <p>You acknowledge and agree that your consent to electronic communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act (the &quot;Act&quot;), and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.</p>

                <p><b>Contact Us</b></p>
                <p>You may contact us if you have problems viewing your electronic documents, to request paper copies, manage your delivery preferences, or withdraw your consent to this Disclosure and Consent.</p>

                <p>By telephone: 1-866-449-4514</p>
                <p>By mail: P.O. Box 3220, Buffalo, NY 14240-3220</p>

                <p><b>Acceptance and Consent.&zwj;</b></p>
                <p>By consenting to this Disclosure and Consent, you agree to the following:</p>
                <ul type="disc">
                    <li>You have read, understand and agree to be bound by the terms and conditions described above and consent to receive electronic communications according to the process described above. You understand that you may incur costs, including but not limited to charges from your internet service provider, in accessing and/or viewing such document(s).</li>
                    <li>You understand and agree that:</li>

                    <ol start="1">
                        <li>certain documents may continue to be delivered to you via U.S. Mail, and in the future some or all of these documents may be made available for you to view electronically in accordance with this Disclosure and Consent, including through the Paperless Statements program;</li>
                        <li>your consent to view documents electronically does not automatically expire and is not limited as to duration;</li>
                        <li>the entity or entities with which you have your accounts and/or their agents may revoke your participation in the Paperless Statements program at any time at their discretion;</li>
                        <li>neither we, nor our agents, will be liable for any loss, liability, cost, expense, or claim for acting upon this authorization or arising from your use of the product or services provided pursuant to this Disclosure and Consent; and</li>
                        <li>in the event that inserts provided along with your account statements contain important information or disclosures concerning my account, you agree to review such inserts in a timely manner.</li>
                    </ol>
                </ul>
            </div>
            <div id="content-2">
                {
                    activeCheck && (
                        <div>
                            <ul>
                                <li><input checked={info.eSignConsent === "1" ? true : false} value={info.eSignConsent} onClick={() => { setInfo({ index: "eSignConsent", value: info.eSignConsent === "1" ? "0" : "1" }); }} type="checkbox" {...register("eSignConsent")} />&nbsp;&nbsp;<span className="d-md-none">I agree to the e-sign disclosure and consent</span></li>
                                <li><label className="d-none d-md-block">I agree to the e-sign disclosure and consent</label></li>
                            </ul>
                            {errors.eSignConsent && errors.eSignConsent.message.map((message) => (
                                <div className="error-message" key={"eSignConsent-" + message}> You must agree before continue. </div>
                            ))}
                        </div>
                    )
                }
                <div className='col-lg-6 col-md-7 col-sm-12 col-xs-12'>
                    <ul>
                        <li><span className="icon"><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;<a href={Setting.productSettings.ESignUrl} target="_blank" rel="noopener noreferrer" className="d-md-none print-text">Electronic Communications Disclosure</a></span></li>
                        <li><a href={Setting.productSettings.ESignUrl} target="_blank" rel="noopener noreferrer" className="d-none d-md-block print-text">Electronic Communications Disclosure</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Esign       