import React from 'react';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';


function OtherOptions() {

    const otherCardSettings = useStoreState(state => state.siteSettingsModel.otherCardSettings);


    return (
        <>
          <div className='row baseOffer'>
            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center' style={{display : "flex", alignItems: "center"}}>
                <h2 className='SpecialOfferText w-100'>Optional Credit Offers:</h2>
            </div>
            <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12'>
                <div className='row'>
                    <div className='col-12 '>
                        <p className='text-center' style={{fontSize:"9pt"}}>
                            Based on your application and credit profile there maybe be other credit offer available to you.
                            If you would like to review and consider these 
                            <br></br>
                            offers please click the button below and you will be automatically redirected to a third party website.
                            <br></br>
                            <strong className='text-xl' style={{fontSize : "10pt"}}>Are you interested in reviewing these credit options today?</strong>
                        </p>
                    </div>
                    <div className='col-12 otherOptionBase' onClick={() => {window.open(otherCardSettings.ICommDeclineOtherOfferUrl, '_blank')}}>
                        <span>
                            <span className='offerMessage'>SHOW OFFERS</span> <span className='otherCardsLink'>-</span><span className='otherCardArrow'><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                        </span>
                    </div>
                </div>
            </div>
          </div>
        </ >
    );
}

export default OtherOptions;