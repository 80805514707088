import { thunk } from 'easy-peasy';

export default {
     getPrequalOfferReservation: thunk((action, payload) => {
        const bearer = sessionStorage.getItem("sessionToken");
         const options = {
             method: "POST", 
             headers: new Headers({                
                 'Content-Type': 'application/json',
                 "authorization": 'bearer ' + bearer 
             }),
             body: JSON.stringify(payload.request)
         }
         fetch("https://localhost:44331/api/application/verify-prequal-application", options)
             .then(response => {
                 if (response.status === 200) {                        
                     return response.json().then(data => {
                         if (data.status === "OK") {
                             payload.callback();
                             
                         }                        
                     })
                 }
                 else if (response.status === 401) {
                 }
             });
     }),

     samplePost: thunk((action, payload) => {
         
         fetch("/api/applicant/samplepost/")
     })

}