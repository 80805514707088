import React from 'react';
import { useStoreState } from 'easy-peasy';


function CallCenter() {
 
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    return (
        <div className='col-12 mt-3'>
            <p>
                In order to activate and use your new credit card when arrives, you will need to submit 
                payment of your ${applicationResult.security_deposit} processing fee. Your account can not be opened or used until this payment
                is complete. If you would prefer to pay this fee over phone, please call us at <strong>1-888-525-3038</strong>.
            </p>
        </div>
    );

}

export default CallCenter;