import { action } from 'easy-peasy';

export default {

    applicationResult: {
        FBConversionMode: "",
        FBInternalPixelID: "",
        FBPixelLightWeightURL: "",
        HashString: "",
        PaymentURL: "",
        ResultWebPage: "",
        ResultWebPageHash: "",
        ResultWebPageStepNum: "",
        conversionvalue: "",
        decisionId : "",
        credit_limit: "",
        decline_message: "",
        failed_metric: "",
        full_name: "",
        hca: "",
        hcdc: "",
        pay_opt: "",
        product_code: "",
        reason: "",
        ref_hash: "",
        ref_number: "",
        result: "",
        security_deposit: "",
        IsGrandbank: false,
        GBAuthId: "",
        GrandbankUrl: ""
    },

    setApplicationResult: action((state, payload) => { state.applicationResult = { ...state.applicationResult, ...payload} })
    
}