import React from "react";
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useStoreActions, useStoreState } from "easy-peasy";
import {IncomeMask, unmasking} from  '../../../services/masking'

function IncomeCalculator({ clearMonthlyIncomeError }) {
    const [show, setShow] = useState(false);
    const [income, setIncome] = useState("");
    const [ incomeError, setIncomeError ] = useState("");
  
    const handleClose = () => {
        if (income) {
            setInfo({
                index: "MonthlyIncome",
                value: income,
            });
            clearMonthlyIncomeError();
            setShow(false);
        } else {
            setIncomeError("Annual Income Is Required");
        }
    }

    const handleShow = () => setShow(true);
    const Setting = useStoreState((state) => state.siteSettingsModel);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);


    function IncomeSetter(e){
        let value = unmasking(e, "$XXX,XXX,XXX,XXX", income) / 12;
        setIncome(value);
    }

    useEffect(() => {
        if (income) {
            setIncomeError("");
        }
    }, [income])

    useEffect(() => setIncome(""), []);

  
    return (
        <>
            <Button style={{backgroundColor : Setting.brandSettings.Tertiary}} className="btn w-100" onClick={handleShow}>
                Calculate Your Monthly Income
            </Button>
  
            <Modal show={show} onHide={handleClose} centered>
                
                <Modal.Body>
                    <div className="col-12">
                        <p>Please enter your total annual income. We'll convert it to monthly income. Only numbers are allowed.</p>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="MonthlyIncome">Your Total Annual Income:</label>
                            <div className="input-container">
                                <input maxLength={8} defaultValue={0} value={IncomeMask(income * 12)} 
                                    onInput={(e) =>{ 
                                        
                                        if (/[0-9]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {

                                            IncomeSetter(e)
                                        }
                                    }} 
                                    type="text" className="form-control" placeholder="Annual Salary" 
                                />
                            </div>
                            { incomeError !== "" && (
                            <div className="error-message"> {incomeError}  </div>
                            )}
                           
                        </div>
                    </div>
                    <div className="col-12">
                        <p>Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation</p>

                    </div>
                    <div className="col-12 text-center">
                        <h5><strong>Your monthly income is:</strong></h5>
                        <h5><strong>{IncomeMask(income) === "$" ? "$0" : IncomeMask(income)}</strong></h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-12 text-center modal-footer-buttons">
                        <Button variant="secondary" className="pt-2 pb-2 mr-2 mb-2" onClick={handleClose}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="pt-2 pb-2 mb-2" onClick={() => {
                            setIncome("");
                            setShow(false);
                        }}>
                            Close this window
                        </Button>
                    </div>
                </Modal.Footer>
                </Modal>
      </>
    );
  }
  
  export default IncomeCalculator;