import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { faPlay, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PixelsService from '../../../../services/pixels';
import AxiosWrapper from '../../../../services/axios';

function GrandBankEntryPoint2() {
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const GBTooltip = useStoreState(state => state.GBText.tooltip)
    const PixelService = new PixelsService();
    const setting = useStoreState(state => state.siteSettingsModel);
    const client = new AxiosWrapper();

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
    }, []);

    function clickRedirect() {
        let id = applicationResult.ref_number;
        client.post("update-gb-redirect-status", { "interstitialPage": false, "ApplicantId": id, "isRedirected": true });
    };

    const GrandBankImg = {
        backgroundImage: "url('/Grand-Bank-Art2.png')",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "650px"
    };

    return (
        <div className="row mb-3 baseOffer grandBrank p-0">
            <div className="row" style={(window.innerWidth >= 990) ? GrandBankImg : null}>
                <div className="col-12">
                    <h4 className="GBTitle">Build or Rebuild Credit with Grand360</h4>
                </div>
                <div className="col-12 d-lg-none p-0 d-flex justify-content-center">
                    <img src='/Grand-Bank-Art2-mobile.png' alt='Grand Bank' style={{ width: "80%", marginTop: "-5%" }}/>
                </div>
                <div className="col-lg-5"></div>
                <div className="col-xs-12 col-sm-12 col-lg-7">
                    <ul className='p-3'>
                        <li className='d-flex flex-row'>
                            <span className='mr-3' style={{ marginTop: "1%" }}><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList font-weight-bold'>Turn your credit around with G360 Credit Builder! Designed for people who want to build or rebuild their credit while building savings.
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="age-tooltip" >{GBTooltip.creditbuilder}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='xs' icon={faQuestionCircle} /></span>
                                </OverlayTrigger>
                            </label>
                        </li>

                        <li>
                            <span className='mr-3' style={{ marginTop: "1%" }}><FontAwesomeIcon color='#e4e9eb' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Plus, so much more:</label>
                        </li>
                        <li>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Get Paid Up To 2 Days Early </label>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.upToDays}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                        </li>
                        <li className='d-none d-sm-block'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Access to up to $150 with Cover Me Advance</label>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.coverMe}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                        </li>
                        <li className='d-flex flex-row d-block d-sm-none'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList mr-3'>Access to up to $150 with Cover Me Advance
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.coverMe}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='xs' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                        <li className='d-none d-sm-block'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Credit Score Simulating/Monitoring tool</label>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.creditScore}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                        </li>
                        <li className='d-flex flex-row d-block d-sm-none'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList mr-3'>Credit Score Simulating/Monitoring tool
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.creditScore}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='xs' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                        <li className='d-none d-sm-block'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList'>Money and Savings Accounts Bundle</label>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.savingsBundle}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='sm' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                        </li>
                        <li className='d-flex flex-row d-block d-sm-none'>
                            <span className='mr-3'><FontAwesomeIcon color='#ed4d4d' size='sm' icon={faPlay} /></span>
                            <label className='GBtextList mr-3'>Money and Savings Accounts Bundle
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="age-tooltip" >{GBTooltip.savingsBundle}</Tooltip>}>
                                <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='xs' icon={faQuestionCircle} /></span>
                            </OverlayTrigger>
                            </label>
                        </li>
                    </ul>
                    <form action={applicationResult.GrandbankUrl} method="POST" target='_blank' className="d-none d-lg-block">
                        <input type="hidden" value={applicationResult.GBAuthId} name="authid" />
                        <button type='submit' className='btn btn-round btn-block tempClass'
                            onClick={() => { PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect() }}
                            style={{ backgroundColor: "#f04d4e", width: "auto", padding: "10%", margin: "auto", fontWeight: "bold" }}
                        >
                            Get Started!
                        </button>
                    </form>
                </div>
            </div>
            <div className='col-12'>
                <div className='row mb-1 benefits' style={{ fontSize: "9pt", paddingLeft: "15px" }}>
                    <div className='col-12 mb-1 pr-2 pl-2'>
                        <p className='text-left p-0' style={{ fontSize: "9pt" }}>Receive all the benefits of Grand360 Money and Savings Bundle for just $5 a month.</p>
                        <p className='text-left p-0' style={{ fontSize: "9pt" }}>By clicking “Get Started” you authorize the sharing of your personal information with Grand Bank for Savings, FSB.</p>
                        <p className='text-left p-0' style={{ fontSize: "9pt" }}>Grand.bank is a trademark of Grand Bank for Savings, FSB. Member FDIC | Equal Housing Lender</p>
                        <form action={applicationResult.GrandbankUrl} method="POST" target='_blank' className="d-block d-lg-none mb-3">
                            <input type="hidden" value={applicationResult.GBAuthId} name="authid" />
                            <button type='submit' className='btn btn-round btn-block tempClass'
                                onClick={() => { PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect() }}
                                style={{ backgroundColor: "#f04d4e", width: "auto", padding: "10%", margin: "auto", fontWeight: "bold" }}
                            >
                                Get Started!
                            </button>
                        </form>
                        <p className='text-left p-0' style={{ fontSize: "9pt" }}>
                            The Grand.bank™ Mastercard<span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span> debit card is issued by Grand Bank for Savings, FSB. Member FDIC. Under license from Mastercard International.
                            Important Information About Procedures for Opening a New Account or Establishing a New Customer Relationship: The USA PATRIOT Act is a federal law that helps the government
                            fight the funding of terrorism and money laundering activities. Federal law requires all U.S. financial institutions to obtain, verify and record information that identifies each person who opens
                            an account or establishes a customer relationship with Grand Bank for Savings, FSB. What this means for you: When you open a bank account, we will ask you for your name, address, date
                            of birth, and other information that will allow us to reasonably identify you. We may also ask to see your driver's license or other identifying documents at any time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GrandBankEntryPoint2;