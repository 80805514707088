import React, { useState, useRef } from "react";
import { useStoreState } from "easy-peasy";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Mail from '../dmPaymentFlow/mailPaymentOption';
import MoneyGram from '../dmPaymentFlow/moneyGramPaymentOption';
import WesternUnion from '../dmPaymentFlow/westernPaymentOption';
import Phone from '../dmPaymentFlow/phonePaymentOption';

function OtherPaymentOptions() {
    const applicant = useStoreState((state) => state.applicantModel.applicant);
    const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
    const [activeKey, setActiveKey] = useState('');

    const handleClick = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    const otherPaymentRef = useRef(null);

    const scrollToComponent = () => {
      if (otherPaymentRef.current) {
        otherPaymentRef.current.scrollIntoView({
          behavior: "smooth", 
          block: "start",
        });
      }
    };

    return (
        <div className="col-12 p-0">
            <div className="card gbCard noRadiusTop">
                <div className="card-body no-bottom-margin row" style={{ marginBottom: "0 !important" }}>
                    <div className="col-12">
                        <form className="w-100">
                            <div className="row">
                                <div className="col-12">
                                    <h3>
                                        Other ways you can fund your card.
                                    </h3>
                                </div>
                            </div>
                            <div className="row card-box mb-3" style={{ width: "95%", marginLeft: "inherit" }}>
                                <div className="col-12">
                                    <div className="result-message">
                                        <div ><strong>Approved Applicant:</strong> {applicant.FirstName || "Test"} {applicant.LastName || "Test"}</div>
                                        <div ><strong>Reference Number:</strong> {applicationResult.ref_number || "16212152"}</div>
                                        {/* this needs to change when we include processinf fee in the backend response */}
                                        <div ><strong>Processing Fee:</strong> $95.00</div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "95%", marginLeft: "inherit" }}>
                                <Accordion
                                    className="custom-accordion"
                                    flush
                                    activeKey={activeKey}
                                    onClick={scrollToComponent}
                                    ref={otherPaymentRef}
                                >
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header onClick={()=>handleClick("1")}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={activeKey === "1" ? faChevronDown : faChevronRight} />
                                                &nbsp;
                                                <div>Make a payment by mail with a Check or Money order</div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Mail />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header onClick={()=>handleClick("2")}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={activeKey === "2" ? faChevronDown : faChevronRight} />
                                                &nbsp;
                                                <div>Make payment in person at MoneyGram</div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <MoneyGram />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header onClick={()=>handleClick("3")}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={activeKey === "3" ? faChevronDown : faChevronRight} />
                                                &nbsp;
                                                <div>Make payment in person at Western Union</div>   
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <WesternUnion />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header onClick={()=>handleClick("4")}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={activeKey === "4" ? faChevronDown : faChevronRight} />
                                                &nbsp;
                                                <div>Make your payment over the phone</div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Phone />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OtherPaymentOptions;
