import React, {useState, useEffect} from 'react'
import { useStoreState } from 'easy-peasy';
import Parser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import marketing from '../../../../services/marketing';
import AxiosWrapper from '../../../../services/axios';

function FlinksTerms({props}) {

    const setting = useStoreState(state => state.siteSettingsModel);
    const [Template, setTemplate] = useState("");
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const utm = new marketing();
    const client = new AxiosWrapper();

    useEffect(() => {
        getTemplate();    
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);


    const getTemplate = async () => {
        let PC = setting.mainSettings.ProductCode;
        if(applicationResult.product_code !== ""){
            PC = applicationResult.product_code;
        }
        localStorage.setItem("TCProductCode", PC)
        let data = await client.get('get-pdf-html-template/'+PC);
        setTemplate(data); 
    }

    return (
        <Modal show={props.showFlinksTerms} onHide={() => props.setShowFlinksTerms(false)} dialogClassName="modal-80w">
            <Modal.Header>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="applicant-disclosure" >
                    {Parser(Template)}
                </div>
                <div className="terms-print">
                    <a  href='/tc' target="_blank" className="button-link" onClick={(e) => {utm.updateSessionReport("disclosureLink")}}>
                        <FontAwesomeIcon icon={faPrint} /> &nbsp; Rates, Fees, Costs and Limitations
                    </a>
                </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button variant="primary" style={{ backgroundColor: setting.brandSettings.Secondary, minWidth: "200px" }} onClick={() => props.setShowFlinksTerms(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FlinksTerms