import React from 'react';
import { useStoreState } from 'easy-peasy';


function CLIFooter() {

    const cfcLogo = useStoreState(state => state.cfcLogo);

    return (
        <div className="container">
            <div>
                <small>
                    FIT, Verve, Matrix, Cerulean, Continental Finance and Simply Gold - Mastercard® and the Mastercard acceptance mark are service marks
                    used by The Bank of Missouri under license from Mastercard International. Cards are issued by The Bank of Missouri.
                </small>
            </div>
            <br />
            <div>
                <small>
                    Reflex, Surge - Mastercard® and the Mastercard acceptance mark are service marks used by Celtic Bank under license from
                    Mastercard International. Cards are issued by Celtic Bank.
                </small>
            </div>
            <div id="cli-footer-company-section" className="text-center">
                <small>
                    <b>Serviced by Continental Finance Company, LLC</b>
                </small>
            </div>
            <div className="text-center">
                <img src={cfcLogo} alt="" width="150"/>
            </div>
        </div>
    );
}

export default CLIFooter;