import { action } from 'easy-peasy';

export default {

    cliResult: {
        status: "",
        message: "",
        result: "",
        limitIncrease: "",
        creditLimit: "",
        referenceNo: "",
        bankName: "",
        productName: "",
        eligibilityDate: ""
    },

    setCLIResult: action((state, payload) => { state.cliResult = payload }),

    cliRequest: {
        ssn: "",
        accountLast4: "",
        monthlyIncome: ""
    },

    setCLIRequest: action((state, payload) => { state.cliRequest = payload }),

}