import { action } from "easy-peasy";

export default {
  applicantResponse: {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Suffix: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    Zip: "",
    Email: "",
    PrimaryPhone: "",
    SecondaryPhone: "",
    dob: "",
    SSN: "",
    MonthlyIncome: "",
    IncomeSource: "",
    HasCheckingAccount: "",
    cashIntent: "",
    ReservationCode: "",
    Resident: false,

    // Terms
    eSignConsent: false,
    creditProtection: "",
    tcAcknowledgement: "false",
    emailOptIn: true,
    cardColorCode: "",
    generalAcknowledgement: "",

    // Additional Cardholder
    hasAdditionalCardholder: "",
    additionalCardholderFirstName: "",
    additionalCardholderMI: "",
    additionalCardholderLastName: "",
    additionalCardholderSuffix: "",

    // Spouse Information
    isMarried: "",
    spouseFirstName: "",
    spouseLastName: "",
    spouseMiddleInitial: "",
    spouseSuffix: "",
    spouseHasDifferentAddress: "",
    spouseAddress1: "",
    spouseAddress2: "",
    spouseCity: "",
    spouseState: "",
    spouseZip: "",
    spouseEmail: "",
  },

  setApplicantResponse: action((state, payload) => {
    state.applicantResponse = { ...state.applicantResponse, ...payload };
  }),
};
