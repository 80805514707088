import React from 'react';
import Layout from './layout/Layout';


import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/custom.css';

// Error handling global variables
window.$errorCode = null;
window.$errorMessage = "";

function App() {
   
    
    
    return (
        <Layout />
    );
}

export default App;
