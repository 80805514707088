import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import LayoutPREQUAL from '../components/flow/prequalClassic/layout';
import LayoutDM from '../components/flow/dm/layout';
import LayoutCFC from '../components/flow/cfc/layout';
import LayoutCROSSSELL from '../components/flow/crosssell/layout';
import LayoutSTANDALONE from '../components/flow/standalone/layout';
import LayoutACTIVATIONPAYMENT from '../components/flow/activation-payment/layout';
import TestBumper from "../components/body/forms/components/TestBumper";
import CookieConsent from '../components/flow/global-elements/cookieConsent';

function Routing() {
  const setAssetsLink = useStoreActions(actions => actions.setAssetsLink);
  const setAssetsImageLink = useStoreActions(actions => actions.setAssetsImageLink);
  const history = useHistory();
  const getSiteSettings = useStoreActions(actions => actions.siteSettingsModel.getSiteSettings);
  const hostname = useStoreState(state => state.siteSettingsModel.domain);;
  const isLoadingSettings = useStoreState(state => state.siteSettingsModel.isLoadingSettings);
  const mainSetting = useStoreState(state => state.siteSettingsModel.mainSettings);
  const setLoadingState = useStoreActions(actions => actions.siteSettingsModel.setIsLoadingSettings);
  const showHeader = useStoreState(state => state.siteSettingsModel.showHeader);
  const cookieConsent = useStoreState(state => state.siteSettingsModel.cookieConsent);
  //Version Release//
  useEffect(() => {
    console.log("Build Version : " + (process.env.REACT_APP_VERSION || "local"))
  }, [])
  //---------------//

  useEffect(() => {

        getSiteSettings(window.location.href).then((data) => {
            if(data === "redirection"){return;}
            if(!data){history.push("/404"); return;}
            setAssetsLink(data.WebAssetsBaseUrlSettings.Cdn);
            setAssetsImageLink(data.WebAssetsBaseUrlSettings.CdnImage.replace("applicationweb/", ""));

      if (data.MainSettings.IsActive === false) {
        history.push("/maintenance")
      }
      setLoadingState(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='newDesign'>
      {isLoadingSettings ? <div className="pre-loader solid"></div> : null}
      {isLoadingSettings ? <div className="pre-loader-img"><FontAwesomeIcon icon={faSpinner} spin /></div> : null}
      {hostname.includes("de-") &&
        history.location.pathname === "/" &&
        (hostname.includes("dev") ||
          hostname.includes("test") ||
          hostname.includes("qa")) && <TestBumper />
      }
      {!isLoadingSettings && showHeader ? <Header /> : null}
      {!isLoadingSettings ?
        <>
          
           {/* PREQUAL CLASSIC Routes */}
           {
            (mainSetting.Flow === "PREQUAL") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutPREQUAL />} />
                </Switch>
              </> : <></>
          }
          {/* DM Routes */}
          {
            (mainSetting.Flow === "DM") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutDM />} />
                </Switch>
              </> : <></>
          }
          {/* CFC Routes */}
          {
            (["CFC"].includes(mainSetting.Flow)) ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutCFC />} />
                </Switch>
              </> : <></>
          }
          {/* CrossSell Routes */}
          {
            (mainSetting.Flow === "CS") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutCROSSSELL />} />
                </Switch>
              </> : <></>
          }
          {/* Standalone Routes */}
          {
            (mainSetting.Flow === "STANDALONE") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutSTANDALONE />} />
                </Switch>
              </> : <></>
          }
          {/* Payments Routes */}
          {
            (mainSetting.Flow === "ACTIVATION_PAYMENT") ?
              <>
                <Switch>
                  <Route path="/" component={() => <LayoutACTIVATIONPAYMENT />} />
                </Switch>
              </> : <></>
          }
          {/* Maintenance and Error Pages */}
        </>
        : null}

      { cookieConsent === null && localStorage.getItem("userActiveConsent") === null ? <CookieConsent /> : null}  
      {!isLoadingSettings ? <Footer /> : null}
    </div>

  );

}

export default Routing;