import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useFormContext } from "react-hook-form";

function AddCardholder(props) {

   
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const text = useStoreState((state) => state.dmText.terms);
   
    const { register, formState: { errors } } = useFormContext();

    const emptyValues = () => {
      if(
          info.additionalCardholderFirstName.trimLeft() === "" &&
          info.additionalCardholderLastName.trimLeft() === "" &&
          info.additionalCardholderMI.trimLeft() === ""
        ){ return true;}
    }

    return (
        <>
                  <div className="row">
                    <div className="col-12">
                      <div className="smallerText mb-2">
                        {text.block1.disclaimerAdditionalCardholder}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderFirstName">
                          First Name*
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={17}
                            value={info.additionalCardholderFirstName}
                            onInput={(e) =>{
                              if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                    if((e.target.value.split(" ").length - 1) < 2){
                                        setInfo({ index: "additionalCardholderFirstName", value: e.target.value.trimLeft() })}
                                    }
                                }
                              }
                            }
                            type="text" className="form-control" placeholder="First Name"
                            {...register("additionalCardholderFirstName")} 
                          />
                        </div>
                        {errors.additionalCardholderFirstName && (
                          <div className="error-message" > {errors.additionalCardholderFirstName.message} </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderMI">
                          Middle Initial
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={1}
                            value={info.additionalCardholderMI}
                            onInput={(e) =>{
                              if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                setInfo({ index: "additionalCardholderMI", value: e.target.value })
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Middle Initial"
                            {...register("additionalCardholderMI", {shouldUnregister :true})}
                          />
                        </div>
                       
                      </div>
                    </div>
                    <div className="col-lg-5 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="additionalCardholderLastName">
                          Last Name
                        </label>
                        <div className="input-container">
                          <input
                            maxLength={25}
                            value={info.additionalCardholderLastName}
                            onInput={(e) =>{
                              if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                    if((e.target.value.split(" ").length - 1) < 2){
                                        setInfo({ index: "additionalCardholderLastName", value: e.target.value.trimLeft() })
                                    }
                                }
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("additionalCardholderLastName")}
                          />
                        </div>
                        {errors.additionalCardholderLastName && (
                            <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          checked={ info.hasAdditionalCardholder === "true" ? true : false }
                          value={info.hasAdditionalCardholder}
                          onClick={(e) => {
                            setInfo({
                              index: "hasAdditionalCardholder",
                              value: info.hasAdditionalCardholder === "true" ? "false" : "true",
                            });
                          }}
                          className="form-check-input"
                          type="checkbox"
                          {...register("hasAdditionalCardholder", {shouldUnregister :true, validate : emptyValues() || "Please confirm the additional cardholder or empty all fields above"})}
                        />
                        <label className="form-check-label" htmlFor="termsPrices">
                          Add this user
                        </label>
                      </div>
                      {errors.hasAdditionalCardholder && (
                            <div className="error-message"> Please confirm the additional cardholder or empty all fields above </div>
                        )}
                    </div>
                  </div>
                </>
    )
}

export default AddCardholder;