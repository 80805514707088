import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Image from 'react-bootstrap/Image';
import ErrorArt from './Error-art.png';
import ErrorArt500 from './500-Error-art.png';
import './error.css';

const Error = () => {
  

 


  return (
    <Container >
      <Row className='row-error'>
        <Col ></Col>
        <Col xs={8}>
          <div className="panel-body text-center">
            <div className="padding-top-4">
              {
                window.$errorCode === 404 ? <Image src={ErrorArt} fluid /> : <Image src={ErrorArt500} fluid />
              }
            </div>
          
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
}

export default Error