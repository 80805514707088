import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import AxiosWrapper from '../../../../../services/axios';
import './Recaptcha.css';
import { useStoreState, useStoreActions } from 'easy-peasy';

const Recaptcha = ({setRecaptchaDisabled, ProductName}) => {
    
    const RecaptchaVerificationClient = new AxiosWrapper();
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const setRecaptcha = useStoreActions(actions => actions.siteSettingsModel.setUseRecaptcha);
    const onChange = async (value) =>{
        const response = await RecaptchaVerificationClient.post(`validate-captcha`, {"token":value,"productName":ProductName});
        
        if(response.Success === true){
            setRecaptcha(false)
        }
        setRecaptchaDisabled(false);
    }
    return (
        <ReCAPTCHA
            sitekey={productSettings.CaptchaKey}
            onChange={onChange}
            size="normal"
        />
        
    )}

export default Recaptcha