import { useStoreState } from 'easy-peasy';


export default class Wildcard { 

    constructor(){
        this.settings = useStoreState(state => state.siteSettingsModel);
        this.applicant = useStoreState(state => state.applicantModel.applicant);
        this.wildcards = [
            {action : "replace", flag : "{ProductName}", value : this.settings.brandSettings.ProductName},
            {action : "Link", flag : "LinkTerms", value : this.settings.productSettings.TermsUrl},
            {action : "Link", flag : "LinkMastercard", value : this.settings.productSettings.TermsUrl},
            {action : "Link", flag : "LinkPrivacy", value : this.settings.productSettings.PrivacyUrl},
            {action : "replace", flag : "{ApplicantName}", value : this.applicant.firstName},
            {action : "replace", flag : "{bankName}", value : this.settings.brandSettings.BankName},
        ]
    }

    update = () => {
        this.settings = useStoreState(state => state.siteSettingsModel);
        this.applicant = useStoreState(state => state.applicantModel.applicant);
        this.wildcards = [
            {action : "replace", flag : "{ProductName}", value : this.settings.brandSettings.ProductName},
            {action : "Link", flag : "LinkTerms", value : this.settings.productSettings.TermsUrl},
            {action : "Link", flag : "LinkMastercard", value : this.settings.productSettings.TermsUrl},
            {action : "Link", flag : "LinkPrivacy", value : this.settings.productSettings.PrivacyUrl},
            {action : "replace", flag : "{ApplicantName}", value : this.applicant.firstName}
        ]

        return this;
    }

    proccess = (text) => {
        // eslint-disable-next-line  no-unused-vars
        for(let [i, rule] of this.wildcards.entries()){
           
            switch(rule.action){

                case 'replace':
                    rule.value = (rule.value === 'Fit') ? rule.value.toUpperCase() : rule.value
                    text = text.replaceAll(rule.flag, rule.value)
                    
                break;
                case 'Link' :
                    text = text.replace("{"+rule.flag+"}"+this.getBetween(rule.flag,text)+"{/"+rule.flag+"}", "<a href='"+rule.value+"' target='_blank'>"+this.getBetween(rule.flag,text)+"</a>")
                break;
                default:
                    
                break;
            }
        }

        return text;
    }

    getBetween = (flag, text) => {
        // eslint-disable-next-line  no-useless-concat
        const result = text.match(new RegExp("{"+flag+"}" + "(.*)" + "{/"+flag+"}"));
       
        return (result !== null && result[1] !== null) ? result[1] : "";
    }

}