import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import '../../../../assets/prequal/prequaloffer.css';
import { useEffect } from "react";
import marketing from "../../../../services/marketing";
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import { Form } from 'react-bootstrap';
import AdobeScript from "../../../../services/adobeScript";

function Offer() {
  const ada = new AudioEyeSupport();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const utms = new marketing();
  const history = useHistory();
  const axios = new AxiosWrapper();
  const { handleSubmit, register, formState: { errors }, setError } = useForm({ mode: "onSubmit" });
  const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
  const setApplicantInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
  const PixelService = new PixelsService();
  const setInitPixels = useStoreActions(actions => actions.setInitPixels);
  const initPixels = useStoreState(state => state.initPixels);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const termsStep = useStoreActions(actions => actions.setDisplayPrequalTerms);
  const adobe = new AdobeScript()
  const storeApplicantData = useStoreActions((actions) => actions.applicantModel.storeApplicantData);

  useEffect(() => {
    if(!initPixels){
      utms.mapper();
      PixelService.initialize().pageView("/offer").event("google", "tracking", "offer").event("tiktok", null, "View Content");
      ada.setup();
      ada.setDYHeaders(process.env.REACT_APP_PREQUAL).setDYContext({ type: "OTHER",  data: ['return_offer_page'] }).DYLandingEvent();

      adobe.setup();
      getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
      setInitPixels(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hashString = axios.findParam("hash");
    setApplicantInfo({ index: "hasCheckingAccount", value: axios.findParam("hascheckingaccount") || "" });
    if(hashString){
        const newHash = hashString.replace("0x", "");
        submitHash(newHash);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  },[])

  const submitHash = async (hash) => {
      const response = await axios.post('verify-prequal-application', { hashString: hash }, true);
      if (response && response.Success === true) {
          setApplicationResult({
              product_code: response.Payload.ProductCode,
              ref_number: response.Payload.ApplicantId,
              credit_limit: response.Payload.CreditLimit,
              result: "APPROVED",
          });
          termsStep(true)
          history.push(`/terms`+window.location.search);
      } else {
          setApplicationResult({ result: "ERROR" });
      };
  }

  const submitRez =  async (data) => {
      var rezRequest = {
          reservationNumber: "",
          referenceNumber: data.referenceNumber,
          hashString: "",
          ssnLast4: data.ssn,
      };
      const response = await axios.post('verify-prequal-application', rezRequest);
      if (response) {
          if(response.ApplicantId){
              utms.updateSessionReport("confirmReservationAttempt1")
              setApplicationResult({product_code: response.ProductCode,  ref_number: response.ApplicantId, result:"APPROVED", credit_limit:response.CreditLimit});
              storeApplicantData({
                PrimaryPhone: response.MobileNo,
                PrimaryPhoneSms: response.MobileNo,
                SecondaryPhone: response.HomeNo,
                SecondaryPhoneSms: response.HomeNo,
              });
              termsStep(true)
              history.push(`/terms`+window.location.search);
          }else{
              setError("referenceNumber",{ type: 'custom', message: 'Unable to verify offer. Please check reference number and try again.' });
          }
        
      } else {
        
      };
  };

  return (
    <div id="prequal-offer-wrapper">
      <div className="row" id="header-text-container" style={{ backgroundColor:  Setting.brandSettings.Primary }}>
          <h1 id="offer-header-text">Finish Right Where You Left Off!</h1>
      </div>
      <div className="container" id="offer-container">
          <form onSubmit={handleSubmit(submitRez)}>
              <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" >
                      <div className="row d-md-none" id="offer-row-left">
                          <b>
                              <ul>
                                  <li>It wont affect your credit score</li>
                                  <li>It takes less than a minute to see if you pre-qualify</li>
                                  <li>Review your offer and apply</li>
                              </ul>
                          </b>
                      </div>
                      <div className="row" id="offer-row">
                          <img src={Setting.brandSettings.CardImage} alt={`${Setting.brandSettings.ProductName} Card`} className="col-lg-12"/>
                      </div>
                      <div className="row" id="offer-row">
                          <p id="reflex-card-text">
                            
                          </p>
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div id="offer-row-left" className="d-none d-sm-none d-md-block row">
                          <b>
                              <ul>
                                  <li>It wont affect your credit score</li>
                                  <li>It takes less than a minute to see if you pre-qualify</li>
                                  <li>Review your offer and apply</li>
                              </ul>
                          </b>
                      </div>
                      <div id="offer-row row">
                          <Form id="offer-form">
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Reference Number</Form.Label>
                                  <div className="input-container" >
                                    <input
                                      maxLength={9}
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="XXXXXXXXXX"
                                      {...register("referenceNumber", {required : "Please enter a reference number.", minLength : { value : 9, message : "Invalid Reservation Number"}})}
                                    />
                                  </div>   
                                 { errors.referenceNumber && ( <div className="error-message text-center"> {errors.referenceNumber.message} </div>)}
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                  <Form.Label>Last 4 of SSN</Form.Label>
                                  <div className="input-container" >
                                    <input
                                      maxLength={4}
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Enter Last 4 numbers of your SSN"
                                      {...register("ssn", {required : "Last four of SSN are missing.", minLength : { value : 4, message : "Please enter the 4 last numbers of your ssn"}})}
                                    />
                                  </div>
                                  { errors.ssn && ( <div className="error-message text-center"> {errors.ssn.message} </div>)}
                              </Form.Group>
                              <button onClick={() => {ada.sendDYEvent('ReturnToApp')}} className="btn btn-block mt-2" style={{ backgroundColor: Setting.brandSettings.Secondary }} type="submit">
                                  Return to Application
                              </button>
                          </Form>
                      </div>
                  </div>
              </div>
          </form>
      </div>
  </div>
  );
}

export default Offer;
