import React, { useState } from "react";
import { useStoreActions } from "easy-peasy";
import AxiosWrapper from "../../../../services/axios";

function TestBumper() {
  const setApplicantResponse = useStoreActions(
    (actions) => actions.applicantResponse.setApplicantResponse
  );
  const [applicantId, setApplicantId] = useState("");
  const apiClient = new AxiosWrapper();
  const [responseMessage, setResponseMessage] = useState("");

  const getApplicantInfo = () => {
    apiClient
      .post(`/get-applicant-information`, { applicantId })
      .then((response) => {
        if (response.Applicant) {
          setResponseMessage(
            `Retrieved Applicant ID ${applicantId} succesfully`
          );
          setApplicantResponse({
            ...response.Applicant,
            ...response.AdditionalCardholder,
            ...response.SpouseInformation,
            MonthlyIncome: String(response.Applicant.MonthlyIncome),
            Over18: true,
            AuthPrequal: true,
            EmailOptIn: true,
            ESignConsent: true,
          });
        } else {
          setResponseMessage(`Applicant ID ${applicantId} not found`);
        }
      });
  };

  return (
    <div>
      <div
        style={{
          borderWidth: "3px",
          borderColor: "black",
          borderStyle: "solid",
          backgroundColor: "#aa0000",
          textAlign: "center",
          color: "white",
          fontSize: "16px",
        }}
      >
        <div>**THIS IS FOR TESTING DE2.0 ONLY**</div>
        <div>
          {" "}
          Fill the Applicant ID to retrieve to automatically fill the form
        </div>
        <input
          type="text"
          name="appicantId"
          placeholder="Enter ApplicantID to fetch data..."
          value={applicantId}
          onChange={(e) => setApplicantId(e.target.value)}
          style={{width: "18%"}}
        ></input>
        <button onClick={getApplicantInfo}>Fetch</button>
        <div style={{ display: "block", fontSize: "12px" }}>
          {responseMessage}
        </div>
      </div>
    </div>
  );
}

export default TestBumper;
