import { action } from 'easy-peasy';

export default {
     
    //State
    visible : false,
    error   : {
        message : "",
        code  : "",
    },
    //Action
    setErrors: action((state, payload) => { state.error = payload }),
    setVisible : action((state, payload) => { state.visible = payload })
}