import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useFormContext } from "react-hook-form";
import '../../../assets/terms/credit-protection.css';
import marketing from "../../../services/marketing";
import PixelsService from '../../../services/pixels';

function CreditProtection() {

    const { register,  formState: { errors } } = useFormContext();    
    const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
    const setting = useStoreState((state) => state.siteSettingsModel);
    const cpLink = useStoreState(state => state.siteSettingsModel.productSettings);
    const assetsBaseUrl = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const utm = new marketing();
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const WaterfallValues = {"CFC" : 14, "STANDALONE" : 20  }
    const utms = new marketing();
    const PixelService = new PixelsService();
 
    const monthlyCharge = setting.mainSettings.Flow === "DM" ? info.CreditProtectionPrice : 1.39; 

    return (
        <div className="col-lg-12">
            
            <div className="container-fluid">
                <div className="row credit-protection">
                    <div className="col-lg-2 text-center whitebg">
                        <div className="credit-protection-img">
                            <img alt="" src={assetsBaseUrl.CdnImage + "Common/creditprot.gif"} />                        
                        </div>                    
                    </div>
                    <div className="col-lg-10 credit-protection-content">
                    {errors.creditProtection ? 
                            <div className="error-message mt-1"> You must choose an option before continue. </div>
                            :
                            <></>
                        }                        
                        <div className="form-check check-fields">
                            <input
                            onClick={(e) => {
                                setInfo({ index: "creditProtection", value: e.target.value, });utm.updateSessionReport("continentalCreditProtection");
                                PixelService.event("google", setting.mainSettings.Flow, "Credit Protection")
                                if(setting.mainSettings.Flow === "CFC" || setting.mainSettings.Flow === "STANDALONE"){ 
                                    utms.trackEvent(
                                        WaterfallValues[setting.mainSettings.Flow], 
                                        setting.mainSettings.Flow, 
                                        applicationResult.ref_number ?? "",
                                        setting.productSettings.ProductId, 
                                        "Flow");}
                                }
                            }
                             name="creditProtection" className="form-check-input" type="checkbox" value="yes" id="credit-protection-yes"  checked={(info.creditProtection === "yes" ? true : false)} {...register("creditProtection")} />
                            <label className="form-check-label" htmlFor="">
                                <b>Yes</b>, I want to purchase optional <a onClick={() => { utm.updateSessionReport("creditProtectionLink") }} href={cpLink.OnlineCreditProtectionUrl} target="_blank" rel="noopener noreferrer" > CONTINENTAL CREDIT PROTECTION </a> (the “Program”). My decision to purchase or
                                not to purchase the Program will not affect any application for credit or the terms of any existing account I have
                                with {brandSettings.BankName}. I understand that:
                            </label>
                        </div>
                        <ul>
                            <li>The monthly charge is ${monthlyCharge} for each $100 of the outstanding Account balance on the date that my monthly Billing Cycle closes.</li>
                            <li>
                                There are conditions, terms, exclusions, and requirements set forth in the <a onClick={() => { utm.updateSessionReport("creditProtectionLink") }} href={cpLink.OnlineCreditProtectionUrl} target="_blank" rel="noopener noreferrer" >CONTINENTAL CREDIT PROTECTION ADDENDUM</a> that may prevent me from receiving Program Benefits.
                            </li>
                            <li>I will not be able to make purchases or obtain cash advances while receiving Program Benefits.</li>
                            <li>I may cancel at any time and if I cancel within 30 days of purchase, I will receive a full refund.</li>
                        </ul>
                        <p>
                            I certify that I have read <a onClick={() => { utm.updateSessionReport("creditProtectionLink") }} href={cpLink.OnlineCreditProtectionUrl} target="_blank" rel="noopener noreferrer" >CONTINENTAL CREDIT PROTECTION ADDENDUM</a>.
                            </p>
                        <div className="form-check check-fields">
                            
                            <input name='creditProtection' className="form-check-input" type="checkbox" value="no" id="credit-protection-no" checked={(info.creditProtection === "no" ? true : false)} onClick={(e) => { setInfo({ index: "creditProtection", value: e.target.value, });  }} {...register("creditProtection")} />
                            <label className="form-check-label" htmlFor="credit-protection-no" >
                                <b>No thank you</b>, I am not interested in the <a onClick={() => { utm.updateSessionReport("creditProtectionLink") }} href={cpLink.OnlineCreditProtectionUrl} target="_blank" rel="noopener noreferrer" > CONTINENTAL CREDIT PROTECTION </a> Program.
                        </label>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    )

}

export default CreditProtection