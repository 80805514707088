export default {
    landing: {
        block1: {
            title:              "The {ProductName} Mastercard Features"
        },
        block2: {
            title : "Need Help?"
        }
    }
};
