import { action, thunk } from "easy-peasy";
import AxiosWrapper from "../services/axios";

export default {
  routingSettings: {
    FaqFooter: false,
    PrivacyHeader: false,
    PrivacyFooter: false,
    TermsHeader: false,
    TermsHeaderCDN: false,
    TermsFooter: false,
    TermsFooterCDN: false,
  },

  setRoutingSettings: action((state, payload) => {
    state.routingSettings = payload;
  }),

  getRoutingSettings: thunk(async (state, payload) => {
    const api = new AxiosWrapper();

    let data = await api.post("get-route-settings", {
      Url: payload.Url,
      Route: (payload.Route !== "/" && payload.Route.slice(-1) === "/") ? payload.Route.slice(0,-1) : payload.Route,
    });

    // If there was a conf retrieved it will push it into state, otherwise it will use the defalut state
    if (data) {
      state.setRoutingSettings(data);
    }
  }),
};
