import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import AxiosWrapper from '../../../services/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import marketing from '../../../services/marketing';
import PixelsService from '../../../services/pixels';
import EntryPoint from './dmPaymentFlow/entryPoint';
import AudioEyeSupport from '../../../services/audioEyeSupport';
import AdobeScript from '../../../services/adobeScript';

function Approved() {

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const setting = useStoreState(state => state.siteSettingsModel);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const cardColors = useStoreState(state => state.siteSettingsModel.CardColors);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const applicantData = useStoreState(state => state.applicantModel.applicant);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const [CardImg,setCardImg] = useState(brandSettings.CardImage);
    const utms = new marketing()
    const client = new AxiosWrapper();
    const ada = new AudioEyeSupport();
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const PixelService = new PixelsService();
    const WaterfallEvents = { "DM" : 10, "PREQUAL" : 6, "CFC" : 18, "STANDALONE" : 24, "CS" : 29};
    const adobe = new AdobeScript();
    //Result event sended to GA

    useEffect(() => {
        const disableBackButton = (event) => {
          event.preventDefault();
          window.history.forward();
        };
    
        window.addEventListener('popstate', disableBackButton);
    
        return () => {
          //window.removeEventListener('popstate', disableBackButton);
        };
      }, []);

    useEffect(() => {
        if(cardColors.length > 0){
            // eslint-disable-next-line array-callback-return
            cardColors.map((card) => {
                if(card.Code === applicantData.cardColorCode){
                    setCardImg(card.ColorCardFileName)
                    return 0;
                }
            })
        }
        
        if(alResult === false){
            PixelService.pageView("/approved").approvedCalls(applicationResult.ref_number)
            ada.setDYContext( { type: "OTHER" , data:["Approved", applicationResult.ref_number ]});
            ada.sendDYEvent({name : 'Approved'})
            utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
            adobe.event('approved')
            getRoutingSettings({ Url: setting.domain, Route: "/approved"});
            setAlResult(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="resultpage">
            <div className="ribbon" style={{ backgroundColor: brandSettings.Primary }}>
            <h1>Congratulations! You Are Approved!</h1>
            </div>
        <div className="container">
            {
                applicationResult.ref_number !== "" ?
                <div className="row mb-3 mt-4" >
                    <div className="col-lg-12 text-center">
                        <h4 style={{"color" : "#f56928"}}>Reference #: {applicationResult.ref_number}</h4>
                    </div>
                </div>
                : 
                <></>
            }
        

            <div className='row mb-0'>
                <div className='card gbCard'>
                    <div className='card-body no-bottom-margin  row' style={{"marginBottom" : "0 !important"}}>
                        <div className="col-lg-4 text-center">
                            <img 
                            style={
                                {
                                    width : (brandSettings.ProductName === "Revel") ? "50%" : "100%"
                                }
                            } 
                            className="col-lg-12" src={CardImg} alt={brandSettings.ProductName} 
                            />
                        </div>
                        <div className="col-lg-8 pt-0 result-message declined">
                            <p className='text-justify' style={{fontSize : "13pt"}}>
                              <strong>Thank you for choosing the {brandSettings.ProductName === "Fit" ? brandSettings.ProductName.toUpperCase() : brandSettings.ProductName } credit card issued by {brandSettings.BankName} and
                        serviced by Continental Finance Company.</strong>
                            </p>
                            <p className='text-left' style={{fontSize : "13pt"}}>
                            <strong>Your new {brandSettings.ProductName === "Fit" ? brandSettings.ProductName.toUpperCase() : brandSettings.ProductName } credit card should arrive in 7 to 10 days. </strong>
                            </p>
                            <p className="decline-reason gbresponse text-center">
                                Initial Credit Limit:&nbsp;&nbsp;&nbsp; <b style={{color: "black"}}><span className="result-credit-limit mobileMaxWidth">${applicationResult.credit_limit}</span> <small>(subject to available credit)</small></b>
                            </p>
                           
                            
                        </div>
                        {
                            brandSettings.ProductName.toUpperCase() === "FIT" && Flow === "DM" &&  process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION" && (
                                <EntryPoint />
                            )
                        }
                        
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-lg-12 reminder">
                    <h3 className="text-center"><FontAwesomeIcon icon={faArrowDown} />&nbsp;&nbsp;Don't forget these great card features:&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowDown} /></h3>
                    <div>
                    <ul>
                        <li className={!client.isMobile() ? "marginMobile" : ""}>Monthly reporting to the three major credit bureaus.</li>
                        <li className={!client.isMobile() ? "marginMobile" : ""}>Use your card at millions of locations worldwide.</li>
                        <li className={!client.isMobile() ? "marginMobile" : ""}>Free online account access 24/7.</li>
                    </ul>
                    </div>
                    <p className="text-center">
                        
                    </p>
                    <div>
                        <small>
                        The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, 
                        national origin, sex, marital status, age (provided that the applicant has the capacity to enter into a binding contract); 
                        because all or part of the applicant's income is derived from a public assistance program; or because the applicant has in good faith exercised his/her 
                        right under the Consumer Credit Protection Act. The federal agency that administers the compliance with this law concerning this creditor is the FDIC Consumer Response Center, 
                        1100 Walnut Street, Box #11, Kansas City, MO 64106.
                        </small>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    );

}

export default Approved;