import React, { useState, useEffect, Fragment } from 'react';
import Parser from 'react-html-parser';

import { useStoreState, useStoreActions } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import AxiosWrapper from '../../../services/axios';
import marketing from '../../../services/marketing';

import '../../../assets/terms/applicantdisclosure.css';

function TC({data}) {
    const applicantApiClient = new AxiosWrapper();    
    const [Template, setTemplate] = useState("");
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productCode = data.productCode;
    const { register,  formState: { errors } } = useFormContext(); 
    const utm = new marketing();
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const activeCheck = useStoreState((state) => state.siteSettingsModel.activateChecks); 

    useEffect(() => {
        getTemplate();    
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        localStorage.setItem("TCProductCode", productCode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])
  
    

    const getTemplate = async () => {        
        let data = await applicantApiClient.get('get-pdf-html-template/' + productCode);
        setTemplate(data); 
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div id="applicant-disclosure" >            
                            {Parser(Template)}                    
                    </div>
                </div>
                {
                    activeCheck && (
                        <div className='col-6'>
                            <div className="form-check pl-5 pt-4">
                                <input checked={info.tcAcknowledgement === "1" ? true : false} value={info.tcAcknowledgement} onClick={(e) => { setInfo({ index: "tcAcknowledgement", value: info.tcAcknowledgement === "1" ? "0" : "1" }); }} className="form-check-input" type="checkbox"   {...register("tcAcknowledgement")} />
                                <label className="form-check-label" htmlFor="termsPrices">
                                    By clicking this box, you agree that you have read the full terms and conditions of the {brandSettings.ProductName} Mastercard credit card offer.
                                </label>
                                {errors.tcAcknowledgement ? 
                                    <div className="error-message"> You must agree before continue. </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    )
                }
                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 pt-3 ' style={{textAlign : (activeCheck) ? "center" : "left"}}>
                    <div className="terms-print">
                        <a  href='/tc' target="_blank" className="button-link" onClick={(e) => {utm.updateSessionReport("disclosureLink"); localStorage.setItem("TCProductCode", productCode)}}>
                            <FontAwesomeIcon icon={faPrint} /> &nbsp; Rates, Fees, Costs and Limitations
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default TC