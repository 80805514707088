import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import DebitCard from "../../global-elements/dmPaymentFlow/debitCard";
import CheckingAccount from "../../global-elements/dmPaymentFlow/checkingAccount";
import OtherPaymentOptions from "../../global-elements/dmPaymentFlow/otherPaymentOptions";
import { useHistory } from "react-router-dom";

export default function Payment() {
  const [activeKey, setActiveKey] = useState("1");
  const history = useHistory();
  const handleSelect = (eventKey) => {
    eventKey !== null && setActiveKey(eventKey);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
  }, [history]);

  return (
    <>
      <div className="payments">
        <Accordion
          className="custom-accordion"
          flush
          onSelect={handleSelect}
          activeKey={activeKey}
        >
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  checked={activeKey === "1"}
                  style={{ backgroundColor: "black" }}
                />
                <div>
                    Pay by Card
                    <span style={{ marginRight: "20px" }}></span> 
                    <span style={{ fontStyle: "italic", color: "orange" }}>Fastest!</span>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <DebitCard />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className='row'>
                  <div className='col-12 col-md-5' style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      checked={activeKey === "2"}
                      style={{ backgroundColor: "black" }}
                    />
                    <div>Checking or Savings Account</div>
                  </div>
                  <div className='col-12 col-md-7'>
                    <p className='mb-0 checkingNote'>Paying with checking or savings account will take an additional 10 days for the funds to clear</p>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <CheckingAccount />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  checked={activeKey === "3"}
                  style={{ backgroundColor: "black" }}
                />
                <div>Other ways you can pay your processing fee</div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <OtherPaymentOptions />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
