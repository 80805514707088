import React from 'react';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import FacebookPixel from 'react-fbq';
import { useStoreState } from "easy-peasy";
import AxiosWrapper from './axios';
import ReactPinterestTag from "react-pinterest-tag";

class Pixels extends React.Component{

    constructor(){
        super();
        this.Flow   = useStoreState((state) => state.siteSettingsModel.mainSettings.Flow);
        this.Pixels = useStoreState((state) => state.siteSettingsModel.Pixels);
        this.client = new AxiosWrapper();
        this.ProductName = useStoreState((state) => state.siteSettingsModel.brandSettings.ProductName);
        this.EmailId = useStoreState(state => state.applicantModel.applicant.Email);
        this.EventId = this.generateEventId();  
    }

    initialize = () => {
        //Initialize Google
        if(this.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)) {
            // eslint-disable-next-line array-callback-return
            ReactGA.initialize(this.Pixels.map((Pixel) => { if(Pixel.Type === process.env.REACT_APP_GAFLAG) return { "trackingId" : Pixel.Tag}}).filter(filter => filter !== undefined));
        }
        if(this.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
            FacebookPixel.initialize({id : this.Pixels.find(Pixel => Pixel.Type === "Facebook")["Tag"]}) 
        }
        if(this.Pixels.find(Pixel => Pixel.Type === "Tiktok")) { 
            TiktokPixel.init(this.Pixels.find(Pixel => Pixel.Type === "Tiktok")["Tag"]);
        }
        if(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")) {  
            if(this.EmailId !== undefined && this.EmailId !== null && this.EmailId.trim() !== "")
            {
                // Enhanced Match using Pinterest Tag and Email Initialization
                ReactPinterestTag.init(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")["Tag"], this.EmailId);     
            }   
            else
            {                   
                ReactPinterestTag.init(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")["Tag"]);            
            }
        }
        return this;
    }   

    pageView = (page = window.location.pathname) => {
        if(this.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)){
            ReactGA.send({ hitType: "pageview", page: page + window.location.search });
        }
        if(this.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
            FacebookPixel.pageView()
        }
        if(this.Pixels.find(Pixel => Pixel.Provider === "Mntn")) { 
            this.mntnLocation()
        }
        if(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")) {        
            ReactPinterestTag.pageView();                           
            ReactPinterestTag.track("pagevisit",{
                property: page,
                event_id: this.EventId,
                lead_type: this.Flow,
                line_items: [{
                        product_name: this.ProductName
                    }]
              });  
        }
        return this;
    }

    event = (provider = "",category = "tracking", action = "event") => {
        switch(provider){
            case "google" :
                if(this.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)){
                    ReactGA.event({category, action});
                }
            break;
            case "facebook" :
            
            break;
            case "tiktok" :
                if(this.Pixels.find(Pixel => Pixel.Type === "Tiktok")) { 
                    TiktokPixel.track(action);
                }
            break;
            case "Pinterest" :
                if(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")) {                                                  
                    ReactPinterestTag.track(action,
                        {
                            property :category ,
                            event_id: this.EventId,     
                            lead_type: this.Flow,                     
                            line_items: [{
                                product_name: this.ProductName                                
                            }]
                        });
                }
            break;
            default:

            break;
        }

        return this;
    }

    approvedCalls = (refNumber) => {
        //Google Approved call
        if(this.Pixels.find(Pixel => Pixel.Type === process.env.REACT_APP_GAFLAG)){
            ReactGA.event({category: this.Flow, action: "approved",});
        }
        //Tiktok
        if(this.Pixels.find(Pixel => Pixel.Type === "Tiktok")) { 
            TiktokPixel.track("Subscribe");
        }
        //Facebook
        if(this.Pixels.find(Pixel => Pixel.Type === "Facebook")) { 
            FacebookPixel.track("Purchase", {value: '0.00', currency: 'USD'});
        }
        //Hitpath
        this.HitPathCall()
        //amazon
        if(this.Pixels.find(Pixel => Pixel.Provider === "Amazon") && this.Pixels.find(Pixel => Pixel.Type === "conversion")) { 
            this.amazonConversion(this.Pixels.find(Pixel => Pixel.Type === "conversion"))
        }
        //mntn
        if(this.Pixels.find(Pixel => Pixel.Provider === "Mntn")) { 
            this.mtnnConversion(refNumber)
        }        
        //Pinterest
        if(this.Pixels.find(Pixel => Pixel.Type === "Pinterest")) {     
            ReactPinterestTag.track('checkout', {
                order_id: refNumber,
                event_id: this.EventId,
                lead_type: this.Flow,
                line_items: [{
                        product_name: this.ProductName                        
                    }]
            });
        }       
    }

    HitPathCall = () => {
        if(this.Pixels.find(Pixel => Pixel.Provider === "HitPath") && this.Pixels.find(Pixel => Pixel.Type === "Conversion")) {   
            let HitPath = this.Pixels.filter(Pixel => Pixel.Provider === "HitPath");

            if(HitPath.find(Pixel => Pixel.Type === "Conversion")){
                let String = HitPath.find(Pixel => Pixel.Type === "Conversion")["Tag"];
                if(this.client.findParam('hid')){
                    this.InsertPixel(String.replace("[HID_VALUE]", this.client.findParam('hid')), 'HitPathConversion')
                }
            }
        }

        return this;
    }

    InsertPixel = (Pixel, Tag) => {
        var _pix = document.getElementById(Tag);
        if (!_pix) { 
          
            _pix = document.createElement('iframe'); 
            _pix.style.display = 'none'; 
            _pix.setAttribute('src', Pixel); 
            _pix.setAttribute('id',Tag); 
            document.body.appendChild(_pix);
        }

        return this;
    }

    mntnLocation = async () => {
        
        var b = "4.0.0",
            n = "33430",
            additional = "term=value",
            t, r, i;
        try {
            t = window.top.document.referer !== "" ? encodeURIComponent(window.top.document.referrer.substring(0, 2048)) : ""
        } catch (o) {
            t = document.referrer !== null ? document.referrer.toString().substring(0, 2048) : ""
        }
        try {
            r = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location
        } catch (u) {
            r = document.location
        }
        try {
            i = window.top.parent.location.href !== "" ? encodeURIComponent(window.top.parent.location.href.toString().substring(0, 2048)) : ""
        } catch (a) {
            try {
                i = r !== null ? encodeURIComponent(r.toString().substring(0, 2048)) : ""
            } catch (f) {
                i = ""
            }
        }
        // eslint-disable-next-line  no-unused-vars
        var l, c = document.createElement("script"),
            h = null,
            p = document.getElementsByTagName("script"),
            d = Number(p.length) - 1,
            v = document.getElementsByTagName("script")[d];
        if (typeof l === "undefined") {
            l = Math.floor(Math.random() * 1e17)
        }
        // eslint-disable-next-line  no-useless-concat
        h = "dx.mountain.com/spx?" + "dxver=" + b + "&shaid=" + n + "&tdr=" + t + "&plh=" + i + "&cb=" + l + additional;
        c.type = "text/javascript";
        c.src = ("https:" === document.location.protocol ? "https://" : "http://") + h;
        v.parentNode.insertBefore(c, v)    
    }

    mtnnConversion = async (refNumber) => {
       
       
        var p;
        var q;
        var m;
        var o = "33430";
        var l = refNumber; // l="ORDER ID",
        var i = "TOTAL ORDER AMOUNT";
        var c = "";
        var k = "";
        var g = "";
        var j = "";
        var u = "";
        var shadditional = "";
        try {
            p = window.top.document.referer !== "" ? encodeURIComponent(
                window.top.document.referrer.substring(0, 512)
            ) : ""
        } catch (n) {
            p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : ""
        }
        try {
            q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location
        } catch (b) {
            q = document.location
        }
        try {
            m = window.parent.location.href !== "" ? encodeURIComponent(window.parent.location.href.toString().substring(0, 512)) : ""
        } catch (z) {
            try {
                m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : ""
            } catch (h) {
                m = ""
            }
        }
        var A, y = document.createElement("script"),
            w = null,
            v = document.getElementsByTagName("script"),
            t = Number(v.length) - 1,
            r = document.getElementsByTagName("script")[t];
        if (typeof A === "undefined") {
            A = Math.floor(Math.random() * 100000000000000000)
        }
        w = "dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional;
        y.type = "text/javascript";
        y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w;
        r.parentNode.insertBefore(y, r)
       
    }

    amazonConversion = (Pixel) => {
        var _pix = document.getElementById(Pixel.Tag.split("|")[0]);
        if (!_pix) { 
            var protocol = '//'; 
            var a = Math.random() * 1000000000000000000; 
            _pix = document.createElement('iframe'); 
            _pix.style.display = 'none'; 
            _pix.setAttribute('src', protocol + Pixel.Tag.split("|")[1] + '&cb=' + a); 
            _pix.setAttribute('id',Pixel.Tag.split("|")[0]); 
            document.body.appendChild(_pix);
        }
    }

    generateEventId = () => {
        const timestamp = new Date().getTime();
        const randomNum = Math.floor(Math.random() * 10000); // Generates a random number between 0 and 9999
        return `eventId${timestamp}-${randomNum}`;
    };
}

export default Pixels