import React from 'react'

function Proccessing() {

    return (
        <div className="processing-overlay">
            <div className="progress-wrapper">
                <h3 className="progress-processing">Processing</h3>
                <div className="progress-container">
                    <div className="progress-bar">
                        <div className="progress-bar-value"></div>
                    </div>
                </div>
            </div>
            <div className="progress-message">
                <p className="text-center">
                    The process of reviewing your account may take a few minutes to complete. Once the review is finished, we will display the decision. If you would prefer not to wait, an email with the final decision will be sent to you. Thank you for your patience.
                </p>
            </div>
        </div>
    )
}

export default Proccessing