import { action } from 'easy-peasy';

export default {

    applicationResultOffer: {
        status: null,
        reason: "",
        result: "",
        decision: "",
        hardpull: null,
        ref_hash: null,
        ref_number: null

    },

    setApplicationResultOffer: action((state, payload) => { state.applicationResultOffer = { ...state.applicationResultOffer, ...payload} })
    
}