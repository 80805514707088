import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { useLocation } from "react-router-dom";
import marketing from "../../services/marketing";

function AppSiteFooter() {
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const productSettings = useStoreState(
    (state) => state.siteSettingsModel.productSettings
  );
  const [customerService] = useState("tel:" + productSettings.CustomerService);
  const utm = new marketing();
  const routingSettings = useStoreState(
    (state) => state.routingSettingsModel.routingSettings
  );

  const mainSettings = useStoreState(
    (state) => state.siteSettingsModel.mainSettings
  );
  const location = useLocation();
  const applicant = useStoreState((state) => state.applicantModel.applicant);
  const applicationResult = useStoreState(
    (state) => state.applicationResultModel.applicationResult
  );
  const [pCode, setPCode] = useState(mainSettings.ProductCode);
  const MainPath          = useStoreState(state => state.siteSettingsModel.MainPath);

  useEffect(() => {

    if (applicant.ProductCode || applicant.ProductCode !== "") {
      
      setPCode(applicant.ProductCode);
    }
  }, [applicant.ProductCode]);

  useEffect(() => {
    if (
      applicationResult.product_code ||
      applicationResult.product_code !== ""
    ) {
      setPCode(applicationResult.product_code);
    }
  }, [applicationResult.product_code]);

  return (
    <div className="container">
      {location.pathname === "/" && mainSettings.Flow === "CS" && (
        <p>
          * A Pre-approved credit card offer means you meet the initial credit card and underwriting criteria and your 
          application may be approved for this {brandSettings.ProductName}<span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span> Mastercard<span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span> offer 
          if you continue to meet this criteria. See Rates, Fees, Costs and Limitations.  
        </p>
      )}
      <p className="footer-links">
        {routingSettings.PrivacyFooter && (
          <>
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.PrivacyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}   
            >
              Privacy
            </a>
            {(routingSettings.FaqFooter || routingSettings.TermsFooter) && (
              <span> | </span>
            )}
          </>
        )}
        {routingSettings.FaqFooter && (
          <>
            <a
              onClick={(e) => {
                utm.updateSessionReport("faqLink");
              }}
              target="_blank"
              href={productSettings.FaqUrl}
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              FAQ
            </a>
            {routingSettings.TermsFooter && <span> | </span>}
          </>
        )}
        {mainSettings.Flow === "DM" ?
        routingSettings.TermsFooter ? (
          routingSettings.TermsFooterCDN ? (
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.TermsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}
            >
              Terms & Conditions
            </a>
          ) : (
            (window.location.href.indexOf('/declined') > -1 || (window.location.href.indexOf('/grandbank') > -1 && brandSettings.ProductName === 'Fit')) ?
            <a href={productSettings.TermsUrl} target="_blank"
             className={brandSettings.ProductName === 'Reflex' ?
              "footer-links-reflex" : "footer-links"} 
              rel="noopener noreferrer">Terms & Conditions</a> :
            window.location.href.indexOf('/pendinfo') > -1 ?
             <a href={productSettings.TermsUrl} target="_blank" 
             className={brandSettings.ProductName === 'Reflex' ?
              "footer-links-reflex" : "footer-links"} 
              rel="noopener noreferrer">Terms & Conditions</a> :
            <a
              onClick={ (e) => {utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)} }
              href="/tc"
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              Terms & Conditions
            </a>
          )
        ) : null  
        :
        routingSettings.TermsFooter ? (
          routingSettings.TermsFooterCDN ? (
            <a
              onClick={() => {
                utm.updateSessionReport("privacyLink");
              }}
              href={productSettings.TermsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}
            >
              Terms & Conditions
            </a>
          ) : (
            <a
              onClick={ (e) => {utm.updateSessionReport("termsButton"); localStorage.setItem("TCProductCode", pCode)} }
              href="/tc"
              target="_blank"
              rel="noopener noreferrer"
              className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"} 
            >
              Terms & Conditions
            </a>
          )
        ) : null}
      </p>
      
      <>
        <p className="footer-links">
          Have a Question? Call Customer Service at{" "}
          <a
            href={customerService}
            className={brandSettings.ProductName === 'Reflex' ? "footer-links-reflex" : "footer-links"}
          >
            1-866-449-4514
          </a>
        </p>
        <div className="footer-hours">
          <p> Monday - Friday: 7am - 12am</p>
          <p> Saturday - Sunday: 8am - 8pm</p>
          <p> Eastern Standard Time</p>
        </div>
      </>
      <p>
        {
          brandSettings.ProductName.toLowerCase() === 'fit' ? `${brandSettings.ProductName.toUpperCase()}™` 
            : (
                <>
                  {brandSettings.ProductName}
                  <span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span>
                </>
              )
        } is issued by
        {brandSettings.BankName === "Celtic Bank"
          ? " Celtic Bank"
          : " The Bank of Missouri"}, a Member of FDIC pursuant to a license from Mastercard International.
      </p>
      <p>
        Continental Finance provides services for your Account, but is not a bank.
        The bank issuing your card will be identified on the back of your Mastercard<span style={{verticalAlign: "super", fontSize: "0.75em"}}>&reg;</span> and 
        in your Cardholder Agreement, which governs your use of the Account.
      </p>
      <p>
        All trademarks not belonging to Continental Finance are the property of their respective owners.
      </p>
      <p>
        THIS IS A CREDIT SOLICITATION ONLY. CONTINENTAL FINANCE COMPANY, LLC IS NOT THE LENDER.
        INFORMATION RECEIVED WILL BE SHARED WITH ONE OR MORE THIRD PARTIES IN CONNECTION WITH YOUR CREDIT INQUIRY.
      </p>
      <p>
        Obtaining Your Card: The USA PATRIOT Act is a federal law that requires all financial institutions to obtain, 
        verify and record information that identifies each person who opens a Card Account. 
        What this means for you: When you open a Card Account, we will ask for your name, address, date of birth 
        and other information that will allow us to reasonably identify you.
        We may also ask to see your driver's license or other identifying documents at any time.
      </p>
      <p><small>©{new Date().getFullYear()} Continental Finance LLC All rights reserved.</small></p>
      <p className="mb-0">
        <img alt="" src={brandSettings.FooterLogo} />
      </p>
      <p className="text-right mb-0">
        <small>
          {
            ((location.pathname+"/") === ("/"+MainPath) || (location.pathname+"/") === MainPath || (location.pathname.replace("/","") === MainPath.replace('/', ''))) ?
            <>
              Version {process.env.REACT_APP_VERSION || "local"}
            </>
            : 
            <></>
          }
       
        </small>
      </p>
    </div>
  );
}
export default AppSiteFooter;