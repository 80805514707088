import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useEffect } from 'react';

function Trustbox() {
    const Setting = useStoreState(state => state.siteSettingsModel);
    const keys = {
        Surge :     {
            slider :      { template : "54ad5defc6454f065c28af8b", businessunit : "5cefd6f2951de70001a64d1d" },
            microCombo :  { template : "5419b6ffb0d04a076446a9af", businessunit : "5cefd6f2951de70001a64d1d" }
        },
        Cerulean :  {
            slider      : { template : "54ad5defc6454f065c28af8b", businessunit : "5cefd6f6951de70001a64d21" },
            microCombo  : { template : "5419b6ffb0d04a076446a9af", businessunit : "5cefd6f6951de70001a64d21" } 
        },
        Fit :       { 
            slider :      { template : "54ad5defc6454f065c28af8b", businessunit : "5cefd6f4951de70001a64d1f" },
            microCombo :  { template : "5419b6ffb0d04a076446a9af", businessunit : "5cefd6f4951de70001a64d1f" }
        },
        Reflex :    { 
            slider :      { template : "54ad5defc6454f065c28af8b", businessunit : "5cefd6f8951de70001a64d23" },
            microCombo :  { template : "5419b6ffb0d04a076446a9af", businessunit : "5cefd6f8951de70001a64d23" }
        },
        Verve :     { 
            slider :      { template : "54ad5defc6454f065c28af8b", businessunit : "5cefd6efdc20be0001fcbe85" },
            microCombo :  { template : "5419b6ffb0d04a076446a9af", businessunit : "5cefd6efdc20be0001fcbe85" }
        },
        Revel :     { 
            slider :      { template : "56278e9abfbbba0bdcd568bc", businessunit : "641374d21d15e949e5710f35" },
            microCombo :  { template : "5419b6ffb0d04a076446a9af", businessunit : "641374d21d15e949e5710f35" }
        }
    }

    useEffect(() => {
        var scr = document.createElement("script");
        scr.type = "text/javascript";
        scr.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(scr);
    }, [])

    return (
        <>
            <div className="trustpilot-widget" data-locale="en-US" data-template-id={keys[Setting.brandSettings.ProductName]["microCombo"]["template"]} data-businessunit-id={keys[Setting.brandSettings.ProductName]["microCombo"]["businessunit"]} data-style-height="40px" data-style-width="100%" data-theme="light">
                <a href={Setting.productSettings.TrustpilotUrl} target="_blank"  rel="noopener noreferrer">Trustpilot</a>
            </div>
            <div className="trustpilot-widget" data-locale="en-US" data-template-id={keys[Setting.brandSettings.ProductName]["slider"]["template"]} data-businessunit-id={keys[Setting.brandSettings.ProductName]["slider"]["businessunit"]}
                data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-schema-type="Organization">
                <a href={Setting.productSettings.TrustpilotUrl} target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
        </>
        
    );
}

export default Trustbox;