import React from 'react';

import Body from '../route/routing';
import Error404 from '../components/systempages/error404/Error404';
import Error from '../components/systempages/error/Error';
import Maintenance from '../components/systempages/maintenance/Maintenance';
import TC from '../components/flow/prequal/components/TC';
import { Route, Switch } from "react-router-dom";



function Layout() {   
   
    return (
        <div>
            <Switch>
                <Route path="/tc"            component={() => <TC         />}        exact />
                <Route path="/error"         component={() => <Error         />}     exact />
                <Route path="/404"           component={() => <Error404         />}     exact />
                <Route path="/maintenance"   component={() => <Maintenance   />}     exact />
                <Route path="/"              component={() => <Body   />}      />
            </Switch>           
        </div>
    );
}

export default Layout;