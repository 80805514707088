import React from 'react';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import marketing from '../../../services/marketing';


function Features() {

    const mainSettings = useStoreState(state => state.siteSettingsModel.mainSettings);
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const utm = new marketing();
    const bulletStyle = {
        color: mainSettings.Flow === "PREQUAL" ? brandSettings.Secondary : brandSettings.Primary
    };

    const btnViewTermsStyle = {
        backgroundColor: brandSettings.Primary,
        marginTop: "15px"
    };

    const showCardAndTitle = () => {
        if (mainSettings.Flow === "PREQUAL") {
            return (<div>
                <div className='col-lg-12' style={{"textAlign" : "center"}}>
                <img style={{"marginBottom" : "0 !important"}} alt="" src={brandSettings.CardImage} className="col-lg-12 card-img" />
                {
                    brandSettings.ProductName === "Surge" ? 
                    <p style={{"fontSize" : "small", "color" : "black"}}>A $9.95 fee applies on Premium Designs.</p>
                    :
                    <></>
                }
                
                </div>
                

                <div className="col-lg-12">
                    <h2><b>The {brandSettings.ProductName} Mastercard® Features</b></h2>
                </div>
            </div>);
        }
        else if (mainSettings.Flow === "CFC") {
            return null;
        }
    }

   

  
    

    return (
        <div>
            {showCardAndTitle()}
            {productSettings.Features.map((i, j) => {
                return (
                    <div className="feature-item col-lg-12" key={j}>
                        <div className="feature-bullet">
                            <span style={bulletStyle}>
                                <FontAwesomeIcon icon={faCircle} />
                            </span>
                        </div>
                        <div className="feature-title">
                            <h4>
                                <b>{i.Title}</b>
                            </h4>
                        </div>
                        <div className="p feature-desc"><span dangerouslySetInnerHTML={{ __html: i.Description.replace('%terms and conditions%', '<a href="' + productSettings.TermsUrl + '" target="_blank">terms and conditions</a>') }} /></div>
                    </div>
                );
            })}
            {mainSettings.Flow === "PREQUAL" ?
                < div className="col-lg-12" align="center">
                    <a id="btn-view-terms" onClick={() => {utm.updateSessionReport("termsButton")}} className="btn" href={productSettings.TermsUrl}  rel="noopener noreferrer" style={btnViewTermsStyle} target='_blank'>See Full Terms</a>
                </div> : null
            }
        </div >
    );
}

export default Features;