let metadata = {
    "prequal.yoursurgecard.com" : {
        "title"       : "Double Your Credit Limit with Surge Mastercard",
        "description" : "Check to see if you have a pre-approved Surge Mastercard offer.",
        "keywords"    : "Surge Mastercard, Continental Finance, Double Credit Limit, Credit Cards for Bad Credit, credit card application",
        "author"      : "Continental Finance Company, LLC",
        "og:type"     : "website",
        "og:title"    : "Double Your Credit Limit with Surge Mastercard",
        "og:description" : "Check to see if you have a pre-approved Surge Mastercard offer.",
        "og:image"       : "https://images.continentalfinance.net/applicationweb/Surge/banner.png",
    },
    "localhost" : {
        "title"       : "Double Your Credit Limit with Surge Mastercard",
        "description" : "Check to see if you have a pre-approved Surge Mastercard offer.",
        "keywords"    : "Surge Mastercard, Continental Finance, Double Credit Limit, Credit Cards for Bad Credit, credit card application",
        "author"      : "Continental Finance Company, LLC",
        "og:type"     : "website",
        "og:title"    : "Double Your Credit Limit with Surge Mastercard",
        "og:description" : "Check to see if you have a pre-approved Surge Mastercard offer.",
        "og:image"       : "https://images.continentalfinance.net/applicationweb/Surge/banner.png"
    }
}

export default metadata